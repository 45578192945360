import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from '../../redux/withRouter';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import { toast } from 'react-toastify';
import { ReactSortable } from 'react-sortablejs';
import { Helmet } from 'react-helmet-async';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Checkbox,
	Divider,
	FormControlLabel,
	ListItem,
	ListItemIcon,
	Radio,
	RadioGroup,
	Tab,
	Tabs,
	TextField,
	Typography,
	Chip,
	Paper,
	Grow,
	Slide,
	Toolbar,
	LinearProgress,
	Skeleton,
	Dialog,
	DialogContent,
	CircularProgress,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CustomButton from '../SocialComponents/CustomButton';
import CustomInput from '../SocialComponents/CustomInput';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import FollowerCount from '../SocialComponents/FollowerCount';
import FollowingCount from '../SocialComponents/FollowingCount';
import Searchbar from '../SocialComponents/Searchbar';
import Recipients from '../SocialComponents/Recipients';
import DateAndTimePicker from '../SocialComponents/DateAndTimePicker';
import ConfirmationDialog from '../SocialComponents/ConfirmationDialog';
import RefreshSite from '../SocialComponents/RefreshSite';
import LetterTemplate from './LetterTemplate';
import gbaLogo from '../../util/Images/gbaLogo.png';
import logo from '../../util/Images/logo.svg';
import gbLetterBackground from '../../util/Images/gbLetterBackground.jpg';
import {
	isEmail,
	clearQueriedUsers,
	deleteRecipients,
	addRecipients,
	uploadGoodbyeLetter,
	uploadFile,
	fetchGoodbyeLetter,
	clearSuccessUpdatingGoodbyeLetter,
	deleteGoodbyeLetter,
	setDateTimer,
	fetchRecipientsData,
	setAllEmailRecipients,
	setGoodbyeLetterData,
	setOpenedDialog,
	resetAudioFile,
	noImgUrl,
	titleCase,
	transformKey,
	clearAudioFile,
	clearErrorUpdatingLetter,
	clearAllLetterErrros,
	isSameDay,
	dim,
	trimFileName,
	scrollToTopFunc,
	launchConfetti,
} from '../../redux/actions/dataActions';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailIcon from '@mui/icons-material/Email';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import TimerIcon from '@mui/icons-material/Timer';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import TodayIcon from '@mui/icons-material/Today';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CreateIcon from '@mui/icons-material/Create';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import InfoIcon from '@mui/icons-material/Info';
import GavelIcon from '@mui/icons-material/Gavel';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Groups3Icon from '@mui/icons-material/Groups3';
import FolderIcon from '@mui/icons-material/Folder';
import PlagiarismSharpIcon from '@mui/icons-material/PlagiarismSharp';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarSharpIcon from '@mui/icons-material/DirectionsCarSharp';
import EmojiObjectsSharpIcon from '@mui/icons-material/EmojiObjectsSharp';
import WatchSharpIcon from '@mui/icons-material/WatchSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import HttpsIcon from '@mui/icons-material/Https';
import PasswordIcon from '@mui/icons-material/Password';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import SwapVertSharpIcon from '@mui/icons-material/SwapVertSharp';
import VolunteerActivismSharpIcon from '@mui/icons-material/VolunteerActivismSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import InboxIcon from '@mui/icons-material/Inbox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Media from 'react-media';
import { Navigate } from 'react-router-dom';
import AudioRecorder from '../Audio/AudioRecorder';
import Progress from '../SocialComponents/Progress';
import AudioPlayer from '../MediaPlayers/AudioPlayer';
import { FileDrop } from 'react-file-drop';
import CustomPopover from '../CustomPopover/CustomPopover';
import countdown from '../Countdownjs/countdown';
import TimerCountdown from '../PostTimer/TimerCountdown';
import SendIcon from '@mui/icons-material/Send';
import FooterLetterLogo from './FooterLetterLogo';
import { t } from 'i18next';

const filter = createFilterOptions();
const initialValues = {
	goodbyeMessage: '',
	title: '',
	checklist: [{ content: '' }],
	keyContacts: [{ content: '', name: '', number: '' }],
	contacts: [{ content: '', name: '', number: '' }],
	importantDocuments: [{ type: '', location: '', additional: '' }],
	personalInformation: [
		{
			fullName: '',
			birthDate: '',
			fathersFullName: '',
			mothersFullName: '',
			address: '',
			additional: '',
		},
	],
	devicePasswords: [{ device: '', location: '', password: '' }],
	mediaPasswords: [{ website: '', location: '' }],
	pets: [
		{
			name: '',
			food: '',
			medicationAndAllergies: '',
			emergencyCaregiver: '',
			emergencyCaregiverNumber: '',
			vetName: '',
			vetNumber: '',
			additional: '',
		},
	],
	assets: [
		{
			asset: '',
			location: '',
			keyLocation: '',
			importantDocuments: '',
			action: '',
			additional: '',
		},
	],
	heirlooms: [
		{
			item: '',
			location: '',
			additional: '',
			action: '',
		},
	],
	owed: [
		{
			lender: '',
			loanType: '',
			additional: '',
			owed: '',
		},
	],
	lifePreferences: '',
	funeralArrangements: '',
	customTopics: [],
};

const defaultLetterStrings = [
	'goodbyeMessage',
	'funeralArrangements',
	'lifePreferences',
	'title',
];

const defaultTopics = [
	{ key: 'title' },
	{ key: 'goodbyeMessage' },
	{ key: 'checklist' },
	{ key: 'keyContacts' },
	{ key: 'contacts' },
	{ key: 'importantDocuments' },
	{ key: 'personalInformation' },
	{ key: 'devicePasswords' },
	{ key: 'mediaPasswords' },
	{ key: 'pets' },
	{ key: 'assets' },
	{ key: 'heirlooms' },
	{ key: 'owed' },
	{ key: 'lifePreferences' },
	{ key: 'funeralArrangements' },
	{ key: 'customTopics' },
];

class GoodbyeLetter extends Component {
	constructor(props) {
		super(props);
		this.containerRefStep3 = createRef(null);
	}
	state = {
		goodbyeMessage: '',
		lifePreferences: '',
		funeralArrangements: '',
		letterOrder: [],
		checklist: [
			{
				id: Math.random().toString(),
				content: '',
			},
		],
		keyContacts: [
			{
				id: Math.random().toString(),
				name: '',
				content: '',
				number: '',
			},
		],
		contacts: [
			{
				id: Math.random().toString(),
				name: '',
				content: '',
				number: '',
			},
		],
		importantDocuments: [
			{
				id: Math.random().toString(),
				type: '',
				location: '',
				additional: '',
			},
		],
		personalInformation: [
			{
				id: Math.random().toString(),
				fullName: '',
				birthDate: '',
				fathersFullName: '',
				mothersFullName: '',
				address: '',
				additional: '',
			},
		],
		devicePasswords: [
			{
				id: Math.random().toString(),
				device: '',
				location: '',
				password: '',
			},
		],
		mediaPasswords: [
			{
				id: Math.random().toString(),
				website: '',
				location: '',
			},
		],
		pets: [
			{
				id: Math.random().toString(),
				name: '',
				food: '',
				medicationAndAllergies: '',
				emergencyCaregiver: '',
				emergencyCaregiverNumber: '',
				vetName: '',
				vetNumber: '',
				additional: '',
			},
		],
		assets: [
			{
				id: Math.random().toString(),
				asset: '',
				location: '',
				keyLocation: '',
				importantDocuments: '',
				action: '',
				additional: '',
			},
		],
		heirlooms: [
			{
				id: Math.random().toString(),
				item: '',
				location: '',
				action: '',
				additional: '',
			},
		],
		owed: [
			{
				id: Math.random().toString(),
				lender: '',
				owed: '',
				loanType: '',
				additional: '',
			},
		],
		customTopics: [],
		changedTitles: [],
		open: false,
		panel: 'letters',
		letterCollection: 'sentLetters',
		recipientType: 'user',
		recipientEmail: '',
		emailErrors: {},
		queryField: 'Users',
		showUsers: true,
		showFollowers: false,
		showFollowing: false,
		expanded: '',
		releaseNow: true,
		confirmationDialog: false,
		letterConfirmationDialog: false,
		newLetter: false,
		newLetterConfirmationDialog: false,
		editLetterId: '',
		title: '',
		openedDeleteLetterId: '',
		hasGoodbyeLetter: false,
		mountedOnce: false,
		readLetter: false,
		setUndoDeletedFile: false,
		fileFormData: '',
		fileName: '',
		stateFileToUpload: null,
		deletedStateFileToUpload: null,
		mimetype: '',
		fileDragOver: false,
		exceededFileSize: '',
		fileErrors: '',
		fileType: '',
		deletedFileType: '',
		deletedFileName: '',
		deletedFileFormData: '',
		storageAudioUrl: '',
		animateLetterId: '',
		updateLetter: false,
	};

	componentDidMount() {
		let letterIdLocation = this.props.location.state?.loadLetterId;

		this.props.setOpenedDialog(true);
		if (this.props.data.editedAudioFile) {
			this.props.resetAudioFile();
		}
		if (this.props.data.editedAudioFile || this.props.data.deletedAudioFile) {
			this.props.clearAudioFile();
		}

		if (
			Object.keys(this.props.user.credentials).length > 0 &&
			this.props.user.authenticated
		) {
			const paramsLetterId = this.props.params.letterId;
			// console.log('paramsLetterId', paramsLetterId);

			if (this.props.user.credentials.canRecoverLetter.length > 0) {
				if (this.props.data.letterSendersData.length === 0) {
					const senderIds = this.props.user.credentials.canRecoverLetter.map(
						(obj) => obj.userId,
					);
					const uniqueSenders = [];
					senderIds.forEach((id) => {
						if (!uniqueSenders.includes(id)) {
							uniqueSenders.push(id);
						}
					});
					this.props.fetchRecipientsData(uniqueSenders, 'letter');
				}

				if (!paramsLetterId) {
					this.setState({
						panel: 'letters',
					});
				}
			}

			if (paramsLetterId) {
				let indx = this.props.user.credentials.letters.findIndex(
					(obj) => obj.letterId === paramsLetterId,
				);
				let indx2 = this.props.user.credentials.unreleasedLetters.findIndex(
					(obj) => obj.letterId === paramsLetterId,
				);
				if (indx === -1 && indx2 === -1) {
					toast.info(t('removed_or_deleted', { content: t('letter') }), {
						position: 'bottom-left',
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						containerId: 'app',
						autoClose: 8000,
						icon: <HistoryEduIcon />,
					});
				} else {
					const letterCol =
						this.props.location.state?.data === 'letterReleasesIn24' ||
						this.props.location.state?.data === 'letterReleasesIn72'
							? 'unreleasedLetters'
							: 'sentLetters';
					const loadFrom =
						indx !== -1
							? this.props.user.credentials.letters
							: this.props.user.credentials.unreleasedLetters;

					const letterIndx = indx !== -1 ? indx : indx2;
					let obj = loadFrom[letterIndx];

					if (obj) {
						this.setState({
							editLetterId: obj.letterId,
							letterCollection: letterCol,
							animateLetterId: paramsLetterId,
						});
						this.loadLetter(obj.letterId, true);
					}
				}
			} else if (letterIdLocation) {
				let indx = this.props.user.credentials.canRecoverLetter.findIndex(
					(obj) => obj.letterId === letterIdLocation,
				);

				if (indx === -1) {
					toast.info(t('removed_or_deleted', { content: t('letter') }), {
						position: 'bottom-left',
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						containerId: 'app',
						autoClose: 8000,
						icon: <HistoryEduIcon />,
					});
				} else {
					let obj = this.props.user.credentials.canRecoverLetter[indx];
					this.setState({
						readLetterFrom: obj.userId,
					});
					this.handleReadLetter(obj.letterId);
				}
			} else if (
				this.props.user.credentials.unreleasedLetters.length > 0 &&
				Object.keys(this.props.data.goodbyeLetter).length === 0
			) {
				let letterId =
					this.props.user.credentials.unreleasedLetters.length > 1
						? ''
						: this.props.user.credentials.unreleasedLetters[
								this.props.user.credentials.unreleasedLetters.length - 1
						  ].letterId;
				this.setState({
					panel: 'letters',
					letterCollection: 'unreleasedLetters',
					editLetterId: letterId,
				});
			} else if (this.props.user.credentials.letters.length > 0) {
				this.setState({
					panel: 'letters',
					letterCollection: 'sentLetters',
				});
			} else if (Object.keys(this.props.data.goodbyeLetter).length > 0) {
				if (
					this.props.user.credentials.unreleasedLetters.findIndex(
						(obj) => obj.letterId === this.props.data.goodbyeLetter.letterId,
					) !== -1
				) {
					this.setState({
						panel: 'preview',
						editLetterId: this.props.data.goodbyeLetter.letterId,
					});
				} else {
					this.setState({
						panel: 'letters',
					});
				}
			} else if (
				Object.keys(this.props.user.credentials).length === 0 ||
				(this.props.user.credentials.letters.length === 0 &&
					this.props.user.credentials.unreleasedLetters.length === 0 &&
					this.props.user.credentials.canRecoverLetter.length === 0)
			) {
				this.setState({
					panel: 'intro',
				});
			}
		}
	}

	loadLetter = (letterId, open, action) => {
		const goodbyeLetter = this.props.data.goodbyeLetter;
		if (open && goodbyeLetter.letterId === letterId) {
			this.setState({
				panel: 'preview',
				letterConfirmationDialog: false,
			});
		} else if (
			action ||
			(open &&
				goodbyeLetter.letterId !== letterId &&
				this.updateGoodbyeLetterState('close'))
		) {
			if (Object.keys(goodbyeLetter).length > 0) {
				this.props.setGoodbyeLetterData({});
			}
			this.props.fetchGoodbyeLetter(letterId);
			this.setState({
				panel: 'preview',
				editLetterId: letterId,
				hasGoodbyeLetter: false,
				updateLetter: false,
			});
		} else {
			this.setState((prevState) => ({
				letterConfirmationDialog: !prevState.letterConfirmationDialog,
			}));
		}
	};

	handleReadLetter = (letterId) => {
		if (this.props.data.receivedGoodbyeLetter.letterId !== letterId) {
			this.props.fetchGoodbyeLetter(letterId, true);
		}
		this.setState({
			readLetter: true,
		});
	};

	openDateTimePicker = (event) => {
		event.preventDefault();
		const dateInput = document.getElementById('dateInputClicker');
		dateInput.click();
	};
	handleCloseLetter = () => {
		this.setState({
			readLetter: false,
		});
	};

	componentDidUpdate(prevProps) {
		if (
			this.props.user.credentials.unreleasedLetters.length <
				prevProps.user.credentials.unreleasedLetters.length &&
			this.props.user.credentials.letters.length >
				prevProps.user.credentials.letters.length
		) {
			this.props.launchConfetti(1);
			this.setState({
				letterCollection: 'sentLetters',
			});
		}

		if (
			!this.state.hasGoodbyeLetter &&
			Object.keys(this.props.data.goodbyeLetter).length > 0
		) {
			let goodbyeLetter = this.props.data.goodbyeLetter;
			// console.log(goodbyeLetter);
			const stateWithIds = Object.fromEntries(
				Object.entries(goodbyeLetter).map(([key, value]) => {
					if (
						key === 'letterOrder' ||
						key === 'recipients' ||
						key === 'emailRecipients' ||
						!Array.isArray(value)
					) {
						return [key, value];
					} else {
						return [
							key,
							value.map((item) => ({
								...item,
								id: Math.random().toString(),
							})),
						];
					}
				}),
			);
			if (
				!goodbyeLetter.sent &&
				this.props.user.credentials.unreleasedLetters.findIndex(
					(obj) => obj.letterId === this.props.data.goodbyeLetter.letterId,
				) !== -1
			) {
				let meridiem = dayjs(goodbyeLetter.releaseDate).format('A');

				this.props.setDateTimer({
					dateValue: goodbyeLetter.releaseDate,
					meridiem: meridiem,
					hourValue: parseInt(dayjs(goodbyeLetter.releaseDate).format('h')),
				});
				this.setState({
					releaseNow: false,
				});
			}

			if (goodbyeLetter.recipients && goodbyeLetter.recipients.length > 0) {
				this.props.fetchRecipientsData(
					goodbyeLetter.recipients,
					'letterRecipients',
				);
			}
			if (
				goodbyeLetter.emailRecipients &&
				goodbyeLetter.emailRecipients.length > 0
			) {
				this.props.setAllEmailRecipients(goodbyeLetter.emailRecipients);
			}

			if (goodbyeLetter.audioUrl) {
				this.setState({
					fileName: 'Audio',
					fileType: 'audio',
					stateFileToUpload: goodbyeLetter.audioUrl,
					storageAudioUrl: goodbyeLetter.storageAudioUrl,
				});
			} else if (this.state.stateFileToUpload || this.state.storageAudioUrl) {
				this.setState({
					fileName: '',
					fileType: '',
					stateFileToUpload: '',
					storageAudioUrl: '',
				});
			}
			this.setState({
				hasGoodbyeLetter: true,
				...stateWithIds,
			});
		}

		if (
			this.props.data.errorLoadingGoodbyeLetter !== null &&
			this.state.panel === 'preview'
		) {
			this.setState({
				panel: 'letters',
			});
		}
		if (this.props.data.successUpdatingGoodbyeLetter !== '') {
			let goodbyeLetter = this.props.data.goodbyeLetter;
			const stateWithIds = Object.fromEntries(
				Object.entries(goodbyeLetter).map(([key, value]) => {
					if (
						key === 'letterOrder' ||
						key === 'recipients' ||
						key === 'emailRecipients' ||
						!Array.isArray(value)
					) {
						return [key, value];
					} else {
						return [
							key,
							value.map((item) => ({
								...item,
								id: Math.random().toString(),
							})),
						];
					}
				}),
			);
			this.props.clearSuccessUpdatingGoodbyeLetter({
				message: '',
			});
			if (this.props.data.editedAudioFile || this.props.data.deletedAudioFile) {
				this.props.clearAudioFile();
			}
			scrollToTopFunc();
			let letterCollection = this.state.letterCollection;

			if (this.state.releaseNow) {
				letterCollection = 'sentLetters';
			} else {
				letterCollection = 'unreleasedLetters';
			}

			// SET LETTER
			this.setState({
				panel: 'letters',
				letterCollection: letterCollection,
				editLetterId: '',
				// editLetterId: this.props.data.goodbyeLetter.letterId,
				...stateWithIds,
			});
			if (this.props.data.goodbyeLetter.audioUrl) {
				this.setState({
					fileName: 'Audio',
					fileType: 'audio',
					stateFileToUpload: this.props.data.goodbyeLetter.audioUrl,
					storageAudioUrl: this.props.data.goodbyeLetter.storageAudioUrl,
				});
			}
		}
	}

	focus = (target) => {
		document.getElementById(target).focus();
	};
	changePanel = (panel) => {
		if (this.state.expanded !== panel) {
			this.setState({ expanded: panel });
			// if (this.state.expanded !== false) {
			// 	setTimeout(() => {
			// 		document.getElementById(panel).scrollIntoView({
			// 			behavior: 'smooth',
			// 		});
			// 	}, 600);
			// }
		} else {
			this.setState({ expanded: false });
		}
	};

	fillNextTopic = (topic) => {
		this.setState({
			expanded: topic,
		});
		setTimeout(() => {
			document.getElementById(topic).focus();
		}, 500);
	};
	edit = (id, customTopicId) => {
		if (id === 'devicePasswords' || id === 'mediaPasswords') {
			this.setState({
				panel: 'write',
				expanded: 'passwords',
			});
		} else if (id === 'funeralArrangements') {
			this.setState({
				panel: 'write',
				expanded: 'lifePreferences',
			});
		} else if (id === 'audioRecording') {
			this.setState({
				panel: 'write',
				expanded: 'audioRecording',
			});
		} else {
			this.setState({
				panel: 'write',
				expanded: id,
			});
		}

		let target = customTopicId !== undefined ? customTopicId : id;
		setTimeout(() => {
			document.getElementById(target).focus();
		}, 500);
	};

	radioChange = (event) => {
		let queryField = event.target.value;
		if (this.props.data.queriedUsers.length > 0) {
			this.props.clearQueriedUsers();
		}
		if (queryField === 'Users') {
			this.setState({
				showUsers: true,
				showFollowers: false,
				showFollowing: false,
				queryField: queryField,
			});
		} else if (queryField === 'Followers') {
			this.setState({
				showUsers: false,
				showFollowers: true,
				showFollowing: false,
				queryField: queryField,
			});
		} else if (queryField === 'Following') {
			this.setState({
				showUsers: false,
				showFollowers: false,
				showFollowing: true,
				queryField: queryField,
			});
		}
	};

	handleChecked = (label, value) => {
		if (label === 'recipientType' && label !== this.state.recipientType) {
			this.setState({ recipientType: value });
		} else if (label === 'releaseNow' && value !== this.state.releaseNow) {
			this.setState({ releaseNow: value });
		}
	};

	handlePanelChange = (event, newValue) => {
		scrollToTopFunc();

		if (this.state.recipientEmail) {
			this.addRecipientEmail();
		}

		if (
			newValue === 'sentLetters' ||
			newValue === 'unreleasedLetters' ||
			newValue === 'newLetter'
		) {
			this.setState({
				letterCollection: newValue,
			});
		} else {
			this.setState({ panel: newValue });

			if (newValue === 'write' && !this.state.mountedOnce) {
				setTimeout(() => {
					this.setState({
						expanded: 'goodbyeMessage',
						mountedOnce: true,
					});
				}, 500);
			}
		}
		if (newValue === 'letters') {
			this.updateGoodbyeLetterState();
			let stateLetter = this.state.editLetterId;

			if (stateLetter !== '') {
				if (
					this.props.user.credentials.letters.findIndex(
						(obj) => obj.letterId === stateLetter,
					) !== -1
				) {
					this.setState({
						letterCollection: 'sentLetters',
					});
				} else if (
					this.props.user.credentials.unreleasedLetters.findIndex(
						(obj) => obj.letterId === stateLetter,
					) !== -1
				) {
					this.setState({
						letterCollection: 'unreleasedLetters',
					});
				}
			} else if (this.props.user.credentials.letters.length > 0) {
				this.setState({
					letterCollection: 'sentLetters',
				});
			} else if (this.props.user.credentials.unreleasedLetters.length > 0) {
				this.setState({
					letterCollection: 'unreleasedLetters',
				});
			}
		}

		if (
			newValue === 'sendOn' &&
			this.props.data.dateTimer.dateValue <= Date.now()
		) {
			this.props.setDateTimer({}, true);
		}
		if (newValue === 'preview') {
			this.setPreview();
		}
	};

	openPanel = (panel) => {
		scrollToTopFunc('instant');
		this.setState({
			panel: panel,
		});
		if (panel === 'write') {
			setTimeout(() => {
				this.setState({
					expanded: 'goodbyeMessage',
				});
			}, 100);
		}

		if (this.state.recipientEmail) {
			this.addRecipientEmail();
		}
	};

	setPreview = (panel) => {
		let state = this.state;
		let letterOrder = [];

		if (this.state.editLetterId) {
			let { equal } = this.checkDiffOnSave();
			this.setState({
				updateLetter: !equal,
			});
		}
		if (state.goodbyeMessage !== '') {
			letterOrder.push({
				key: 'goodbyeMessage',
			});
		}

		if (this.state.releaseNow && this.props.data.dateTimer.dateValue) {
			this.props.setDateTimer({}, true);
		}
		this.updateGoodbyeLetterState('preview');

		if (panel) {
			this.setState({
				panel: 'preview',
			});
		}
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleChangeEmailRecipient = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

		if (this.state.emailErrors.error !== '') {
			this.setState({
				emailErrors: {},
			});
		}
	};

	handleChangeLetter = (letterId) => {
		if (letterId === this.state.editLetterId) {
			this.setState({
				editLetterId: '',
			});
		} else {
			this.setState({
				editLetterId: letterId,
			});
		}
	};
	handleDeleteLetter = (letterId, del) => {
		if (del) {
			let collection = '';

			let letters = this.props.user.credentials.letters;

			let unreleasedLetters = this.props.user.credentials.unreleasedLetters;

			if (letters.findIndex((obj) => obj.letterId === letterId) !== -1) {
				collection = 'letters';
			}
			if (
				unreleasedLetters.findIndex((obj) => obj.letterId === letterId) !== -1
			) {
				collection = 'unreleasedLetters';
			}
			this.props.deleteGoodbyeLetter(letterId, collection);
			if (
				Object.keys(this.props.data.goodbyeLetter).length > 0 &&
				this.props.data.goodbyeLetter.letterId === letterId
			) {
				if (
					this.props.data.editedAudioFile ||
					this.props.data.deletedAudioFile
				) {
					this.props.clearAudioFile();
				}
				this.props.setGoodbyeLetterData({});
				this.resetLetter();
			}

			let letterCollection = this.state.letterCollection;
			if (collection === 'letters' && letters.length > 1) {
				letterCollection = 'sentLetters';
			} else if (unreleasedLetters.length > 1) {
				letterCollection = 'unreleasedLetters';
			}
			this.setState({
				openedDeleteLetterId: '',
				editLetterId: '',
				panel: 'letters',
				letterCollection,
			});
		} else {
			this.setState({
				openedDeleteLetterId: letterId,
			});
		}
	};

	handleInputChange = (e, index, key, type, value) => {
		// Create a copy of the state based on the provided type
		const update = [...this.state[type]];

		// Update the specific item within the copy
		update[index][key] = value ? value : e.target.value;

		// Set the updated copy back to the state
		this.setState({
			[type]: update,
		});
	};

	resetLetter = () => {
		let stateUpdate = {
			goodbyeMessage: '',
			lifePreferences: '',
			funeralArrangements: '',
			title: '',
			checklist: this.getInitialValuesForType('checklist'),
			keyContacts: this.getInitialValuesForType('keyContacts'),
			contacts: this.getInitialValuesForType('contacts'),
			importantDocuments: this.getInitialValuesForType('importantDocuments'),
			personalInformation: this.getInitialValuesForType('personalInformation'),
			devicePasswords: this.getInitialValuesForType('devicePasswords'),
			mediaPasswords: this.getInitialValuesForType('mediaPasswords'),
			pets: this.getInitialValuesForType('pets'),
			assets: this.getInitialValuesForType('assets'),
			heirlooms: this.getInitialValuesForType('heirlooms'),
			owed: this.getInitialValuesForType('owed'),
			customTopics: [],
			letterOrder: [],
		};

		const stateWithIds = Object.fromEntries(
			Object.entries(stateUpdate).map(([key, value]) => {
				if (
					key === 'letterOrder' ||
					key === 'recipients' ||
					key === 'emailRecipients' ||
					!Array.isArray(value)
				) {
					return [key, value];
				} else {
					return [
						key,
						value.map((item) => ({
							...item,
							id: Math.random().toString(),
						})),
					];
				}
			}),
		);

		this.setState({
			...stateWithIds,
		});
	};

	handleAddLetter = (bool, action) => {
		let creds = this.props.user.credentials;
		let letters = creds.letters;
		let unreleasedLetters = creds.unreleasedLetters;
		let count = letters.length + unreleasedLetters.length;

		if (count < 10) {
			if (
				action === 'add' ||
				(this.updateGoodbyeLetterState('close') &&
					(Object.keys(this.props.data.goodbyeLetter).length === 0 ||
						!this.state.newLetterConfirmationDialog))
			) {
				this.resetLetter();

				if (
					this.props.data.letterRecipients.length > 0 ||
					this.props.data.emailRecipients.length > 0
				) {
					this.props.deleteRecipients({}, true);
				}
				if (this.props.data.dateTimer.dateValue) {
					this.props.setDateTimer({}, true);
				}
				if (
					this.props.data.editedAudioFile ||
					this.props.data.deletedAudioFile
				) {
					this.props.clearAudioFile();
				}

				if (
					this.props.data.errorLoadingGoodbyeLetter !== null ||
					this.props.data.errorDeletingGoodbyeLetter !== null
				) {
					this.clearLetterErrors();
				}

				this.setState({
					newLetter: true,
					editLetterId: '',
					releaseNow: true,
					newLetterConfirmationDialog: false,
					fileName: '',
					fileType: '',
					stateFileToUpload: null,
					storageAudioUrl: null,
				});

				scrollToTopFunc('instant');

				setTimeout(() => {
					this.props.setGoodbyeLetterData({});
					this.setState({
						panel: 'write',
					});
				}, 100);
				setTimeout(() => {
					this.setState({
						expanded: 'goodbyeMessage',
					});
				}, 150);
			} else {
				this.setState({
					newLetterConfirmationDialog: bool,
				});
			}
		} else {
			toast.info(
				t('cant_exceed', {
					number: 10,
					content: t('letters'),
				}),
				{
					position: 'bottom-left',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					autoClose: 5000,
				},
			);
		}
	};
	handleAdd = ({ type, maxItems, errorMessage, target, newValue, eValue }) => {
		const stateArray = this.state[type];

		let topic = eValue ? eValue : newValue;
		if (stateArray.length < maxItems) {
			let newTopic = this.getInitialValuesForType(type, topic);
			let added = { ...newTopic[0], id: Math.random().toString() };

			if (type === 'customTopics') {
				this.setState({
					[type]: [
						...stateArray,
						{ ...newTopic, id: Math.random().toString() },
					],
				});
				// setTimeout(() => {
				// 	document.getElementById(this.state.customTopics.length - 1).focus();
				// }, 200);
			} else if (type === 'checklist' && this.state.checklist.length > 0) {
				this.setState({
					[type]: [
						...stateArray,
						{ content: '', id: Math.random().toString() },
					],
				});
			} else {
				this.setState({
					[type]: [...stateArray, added],
				});
			}

			if (target) {
				setTimeout(() => {
					document.getElementById(target).focus();
				}, 200);
			}
		} else {
			toast.info(errorMessage, {
				position: 'bottom-left',
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				toastId: 'exceeded',
				containerId: 'app',
				autoClose: 5000,
			});
		}
	};

	handleRemove = (index, type, indexArray) => {
		const update = [...this.state[type]];
		if (indexArray) {
			// Sort the indexes in descending order
			const sortedIndexes = indexArray.sort((a, b) => b - a);
			// Remove elements from the array in descending index order
			sortedIndexes.forEach((indx) => {
				update.splice(indx, 1);
			});
		} else {
			update.splice(index, 1);
		}
		this.setState({
			[type]: update,
		});
	};

	handleRemoveTopic = ({ topic, initialVal }) => {
		let newLetterOrder = this.state.letterOrder.filter(
			(obj) => obj.key !== topic,
		);
		this.setState({
			[topic]: initialVal,
			letterOrder: newLetterOrder,
		});

		if (topic === 'audioRecording') {
			if (this.props.data.editedAudioFile) {
				this.props.resetAudioFile();
			} else {
				this.handleDeleteFile();
			}
		}
	};
	handleRemoveCustomTopic = ({ topic, index }) => {
		if (this.state.customTopics.length === 1) {
			let newLetterOrder = this.state.letterOrder.filter(
				(obj) => obj.key !== 'customTopics',
			);
			this.setState({
				letterOrder: newLetterOrder,
				customTopics: [],
			});
		} else {
			let newCustomTopicOrder = this.state.customTopics.filter(
				(obj, indx) => index !== indx,
			);
			this.setState({
				customTopics: newCustomTopicOrder,
			});
		}
	};

	getInitialValuesForType = (type, newValue) => {
		// Define initial values based on the type
		let obj = { ...initialValues };
		if (type === 'customTopics') {
			obj.customTopics = {
				topic: '',
				content: '',
			};
		}
		if (newValue) {
			obj.customTopics.topic = newValue;
		}

		return obj[type] || {};
	};

	addRecipientEmail = () => {
		if (!isEmail(this.state.recipientEmail)) {
			this.setState({
				emailErrors: { email: t('email_error_invalid_address') },
			});
			this.focus('recipientEmail');
		} else if (
			this.state.recipientEmail.toLowerCase() ===
			this.props.user.credentials.email
		) {
			toast.info(t('you_will_be_notified'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'addRecipientEmail',
			});
		} else if (this.props.data.emailRecipients.length < 15) {
			this.setState({ recipientEmail: '' });
			this.props.addRecipients({ email: this.state.recipientEmail }, 'email');
		} else {
			toast.info(t('only_15_email_recipients'), {
				type: 'info',
				isLoading: false,
				autoClose: 6000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'recipientsExceeded',
			});
		}
	};
	delRecipient = (recipient) => {
		this.props.deleteRecipients(recipient);
	};

	close = (action) => {
		if (action === 'onlyLetter') {
			this.setState({
				panel: 'letters',
				editLetterId: '',
				animateLetterId: '',
				releaseNow: true,
				newLetterConfirmationDialog: false,
				fileName: '',
				fileType: '',
				stateFileToUpload: null,
				storageAudioUrl: null,
				...initialValues,
			});
		} else {
			this.props.history(`/users/${this.props.user.credentials.handle}`, {
				state: { remounted: true },
			});
			if (this.props.data.openedDialog) {
				this.props.setOpenedDialog(false);
			}
		}
		if (this.props.data.dateTimer.dateValue) {
			this.props.setDateTimer({}, true);
		}

		if (this.props.data.editedAudioFile || this.props.data.deletedAudioFile) {
			this.props.clearAudioFile();
		}
		if (this.props.data.errorUpdatingGoodbyeLetter) {
			this.props.clearErrorUpdatingLetter();
		}
		if (
			this.props.data.letterRecipients.length > 0 ||
			this.props.data.emailRecipients.length > 0
		) {
			this.props.deleteRecipients({}, true);
		}

		if (Object.keys(this.props.data.goodbyeLetter).length > 0) {
			this.props.setGoodbyeLetterData({});
		}
		this.setState({
			newLetter: false,
		});
	};

	getObjectDiff = (obj1, obj2, compareRef = false) => {
		return Object.keys(obj1).reduce((result, key) => {
			if (!obj2.hasOwnProperty(key)) {
				result.push(key);
			} else if (isEqual(obj1[key], obj2[key])) {
				const resultKeyIndex = result.indexOf(key);

				if (compareRef && obj1[key] !== obj2[key]) {
					result[resultKeyIndex] = `${key} (ref)`;
				} else {
					result.splice(resultKeyIndex, 1);
				}
			}
			return result;
		}, Object.keys(obj2));
	};

	updateGoodbyeLetterState = (action) => {
		let stateLetterCopy = {};

		defaultTopics.forEach((obj) => {
			stateLetterCopy[obj.key] = this.state[obj.key];
		});

		Object.keys(stateLetterCopy).forEach((key) => {
			if (Array.isArray(stateLetterCopy[key])) {
				stateLetterCopy[key] = stateLetterCopy[key].map(
					({ chosen, selected, id, ...rest }) => rest,
				);
			}
		});

		const initialLetterObject =
			(action === 'close' || action === 'preview') &&
			Object.keys(this.props.data.goodbyeLetter).length > 0
				? this.props.data.goodbyeLetter
				: initialValues;
		// console.log('TEST!', action);
		// console.log('GET DIFF', stateLetterCopy, initialLetterObject);
		let diff = this.getObjectDiff(stateLetterCopy, initialLetterObject);
		// console.log('DIFF', diff);

		diff.forEach((topic) => {
			if (!stateLetterCopy.hasOwnProperty(topic)) {
				diff = diff.filter((top) => top !== topic);
			} else if (typeof stateLetterCopy[topic] === 'string') {
				if (
					stateLetterCopy[topic] !== '' &&
					stateLetterCopy[topic] !== initialLetterObject[topic] &&
					!diff.includes(topic)
				) {
					diff.unshift(topic);
				}
			} else if (typeof stateLetterCopy[topic] !== 'string') {
				if (stateLetterCopy[topic].length > 0) {
					let counter = [];

					// console.log('stateLetterCopy[topic]', stateLetterCopy[topic]);
					stateLetterCopy[topic].forEach((obj, index) => {
						// console.log(stateLetterCopy[topic][index], initialLetterObject);
						if (
							isEqual(
								stateLetterCopy[topic][index],
								initialLetterObject[topic][0],
							)
						) {
							counter.push(index);
						}
					});
					if (counter.length === stateLetterCopy[topic].length) {
						diff = diff.filter((top) => top !== topic);
						this.setState({
							[topic]: [
								{
									...this.getInitialValuesForType(topic)[0],
									id: Math.random().toString(),
								},
							],
						});
					} else if (counter.length > 0) {
						this.handleRemove(false, topic, counter);
					}
				} else {
					diff = diff.filter((top) => top !== topic);
				}
			}
		});

		// console.log('AUDIO 1', this.props.data.editedAudioFile);
		// console.log('AUDIO 2', this.state.stateFileToUpload);
		if (
			this.props.data.editedAudioFile ||
			(this.state.stateFileToUpload &&
				this.state.stateFileToUpload !== this.props.data.goodbyeLetter.audioUrl)
		) {
			let includesCustomTopicsIndex = diff.indexOf('customTopics');

			if (
				// !this.props.data.goodbyeLetter.audioUrl &&
				includesCustomTopicsIndex !== -1
			) {
				diff.splice(includesCustomTopicsIndex, 0, 'audioRecording');
			} else {
				diff.push('audioRecording');
			}
		}

		let letterOrderWithKey = diff.map((topic) => ({
			key: topic,
		}));

		this.reorderArray(letterOrderWithKey);

		if (diff.length === 0) {
			return true;
		} else {
			let changedTitles = [];
			diff.forEach((key) => {
				if (key === 'customTopics') {
					this.state.customTopics.forEach((obj) => {
						const translated = t(titleCase(obj.topic));
						// changedTitles.push(titleCase(obj.topic));
						changedTitles.push(translated);
					});
				} else {
					const translated = t(transformKey(key));
					// changedTitles.push(titleCase(key));
					changedTitles.push(translated);
				}
			});
			this.setState({
				changedTitles: changedTitles,
			});

			return false;
		}
	};

	reorderArray = (newArray) => {
		let orderedArray = [];
		// Iterate through letterOrder and push matching elements from newArray
		this.state.letterOrder.forEach((item) => {
			let foundItem = newArray.find((newItem) => newItem.key === item.key);
			if (foundItem) {
				orderedArray.push(foundItem);
			} else {
				orderedArray.push(item);
			}
		});

		// Push remaining elements from newArray that are not in letterOrder
		newArray.forEach((item) => {
			if (!orderedArray.some((orderedItem) => orderedItem.key === item.key)) {
				orderedArray.push(item);
			}
		});

		this.setState({
			letterOrder: orderedArray,
		});
	};

	checkDiffOnSave = () => {
		let changedData = {};

		let data = this.props.data;
		let queryFrom =
			Object.keys(data.goodbyeLetter).length > 0
				? data.goodbyeLetter.letterOrder
				: defaultTopics;

		let letterOrder = [...this.state.letterOrder];
		queryFrom.forEach((topic) => {
			if (letterOrder.findIndex((obj) => obj.key === topic.key) === -1) {
				if (defaultLetterStrings.includes(topic.key)) {
					changedData[topic.key] = '';
				} else {
					changedData[topic.key] = [];
				}
			}
		});

		if (
			this.state.title !== '' &&
			this.state.title !== data.goodbyeLetter.title
		) {
			changedData.title = this.state.title;
		}

		let mappedOrder = letterOrder.map((topic) => {
			// console.log('TOPIC', topic);
			let stateObj = this.state[topic.key];
			if (Array.isArray(stateObj)) {
				stateObj = stateObj.map(({ chosen, selected, id, ...rest }) => rest);
			}
			if (!isEqual(data.goodbyeLetter[topic.key], stateObj)) {
				// console.log('isDIFF', data.goodbyeLetter[topic.key], stateObj);
				changedData[topic.key] = Array.isArray(stateObj)
					? [...stateObj]
					: stateObj;
			}
			return { key: topic.key };
		});

		// console.log('letterOrder', mappedOrder);
		// console.log(
		// 	'this.props.data.goodbyeLetter.letterOrder',
		// 	data.goodbyeLetter.letterOrder,
		// );

		let mappedSavedLetter =
			Object.keys(data.goodbyeLetter).length > 0 &&
			data.goodbyeLetter.letterOrder.map((topic) => {
				return { key: topic.key };
			});

		if (!isEqual(mappedOrder, mappedSavedLetter)) {
			changedData.letterOrder = mappedOrder;
		}

		let recipients = [];
		data.letterRecipients.forEach((obj) => {
			recipients.push(obj.userId);
		});
		if (
			(recipients.length > 0 && Object.keys(data.goodbyeLetter).length === 0) ||
			!isEqual(recipients.sort(), data.goodbyeLetter.recipients.sort())
		) {
			changedData.recipients = recipients;
		}
		if (
			(data.emailRecipients.length > 0 &&
				Object.keys(data.goodbyeLetter).length === 0) ||
			!isEqual(data.emailRecipients, data.goodbyeLetter.emailRecipients)
		) {
			let emailRecipients = [];
			data.emailRecipients.forEach((email) => {
				emailRecipients.push(email);
			});
			changedData.emailRecipients = emailRecipients;
		}

		if (Object.keys(data.goodbyeLetter).length > 0) {
			if (data.goodbyeLetter.lang !== this.props.user.credentials.lang) {
				changedData.lang = this.props.user.credentials.lang;
			}
			if (data.goodbyeLetter.sent === false) {
				let dateTimer = data.dateTimer;
				if (this.state.releaseNow) {
					changedData.releaseDate = 'now';
				} else if (
					!isEqual(
						new Date(dateTimer.dateValue).toISOString(),
						data.goodbyeLetter.releaseDate,
					)
				) {
					changedData.releaseDate = new Date(dateTimer.dateValue).toISOString();
				}
			}
		} else if (Object.keys(data.goodbyeLetter).length === 0) {
			let dateTimer = data.dateTimer;
			if (this.state.releaseNow) {
				changedData.releaseDate = 'now';
			} else {
				changedData.releaseDate = new Date(dateTimer.dateValue).toISOString();
			}

			changedData.lang = this.props.user.credentials.lang;
		}

		let equal = Object.keys(changedData).length === 0;
		// console.log('changedData', changedData);
		// console.log('isEqual', equal);
		return { equal: equal, changedData: changedData };
	};
	save = () => {
		let { equal, changedData } = this.checkDiffOnSave();

		// console.log('equal', equal);
		// console.log('changedData', changedData);
		let file;
		if (this.props.data.editedAudioFileToUpload) {
			file = this.props.data.editedAudioFileToUpload;
		} else if (this.state.fileType === 'audio' && this.state.fileFormData) {
			file = this.state.fileFormData;
		}
		if (equal && !file) {
			scrollToTopFunc('instant');

			this.setState({
				panel: 'letters',
				editLetterId: '',
			});
			toast.success(t('all_up_to_date'), {
				position: 'bottom-left',
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'app',
				toastId: 'saved',
				autoClose: 5000,
			});
		} else {
			let data = this.props.data;

			if (changedData.letterOrder && changedData.letterOrder.length === 0) {
				this.setState({
					panel: 'sendTo',
				});
				this.setState({
					panel: 'write',
				});
				toast.info(t('add_content_first'), {
					position: 'bottom-left',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					autoClose: 5000,
				});
			} else if (
				data.letterRecipients.length === 0 &&
				data.emailRecipients.length === 0
			) {
				scrollToTopFunc('instant');

				this.setState({
					panel: 'sendTo',
				});
				toast.info(t('select_recipients'), {
					position: 'bottom-left',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					autoClose: 5000,
				});
			} else {
				if (file) {
					this.props.uploadFile({
						userId: this.props.user.credentials.userId,
						data: changedData,
						file: file,
						letter: true,
						letterId: data.goodbyeLetter.letterId,
					});
				} else {
					this.props.uploadGoodbyeLetter(
						changedData,
						data.goodbyeLetter.letterId,
					);
				}
				this.setState({
					hasGoodbyeLetter: true,
					...changedData,
				});
			}
		}
	};

	handleDialog = () => {
		if (this.updateGoodbyeLetterState('close')) {
			this.close();
		} else {
			this.setState((prevState) => ({
				confirmationDialog: !prevState.confirmationDialog,
			}));
		}
	};

	undoDeletedFile = () => {
		let letter = Object.keys(this.props.data.goodbyeLetter).length > 0;

		let type = letter ? 'audio' : this.state.deletedFileType;
		let file = letter
			? this.props.data.goodbyeLetter.audioUrl
			: this.state.deletedStateFileToUpload;
		this.setState({
			setUndoDeletedFile: false,
			stateFileToUpload: file,
			fileType: type,
			fileName: this.state.deletedFileName,
			fileFormData: this.state.deletedFileFormData,
		});
	};
	handleDeleteFile = () => {
		let state = this.state;
		if (state.fileType) {
			this.setState({
				setUndoDeletedFile: true,
				fileFormData: '',
				fileName: '',
				stateFileToUpload: null,
				fileType: '',
				deletedFileFormData: state.fileFormData,
				deletedFileName: state.fileName,
				deletedStateFileToUpload: state.stateFileToUpload,
				deletedFileType: state.fileType,
			});
		}
	};
	clearDeletedFile = (type) => {
		if (type === 'audio') {
			if (this.props.data.deletedAudioFile) {
				this.props.clearAudioFile();
			}
			this.setState({
				setUndoDeletedFile: false,
				fileFormData: '',
				fileName: '',
				stateFileToUpload: null,
				fileType: '',
				deletedFileFormData: '',
				deletedFileName: '',
				deletedStateFileToUpload: '',
				deletedFileType: '',
			});
		}
	};

	setFileType = (type, restart) => {
		if (this.state.fileType === '' && type) {
			this.setState({
				fileType: type,
				deletedFileFormData: '',
				deletedFileName: '',
				deletedStateFileToUpload: '',
				deletedFileType: '',
			});
			if (restart) {
				this.setState({
					setUndoDeletedFile: false,
				});
			}
		}
		if (this.state.deletedFileType === 'audio') {
			this.setState({
				setUndoDeletedFile: false,
			});
		}
	};

	handleOpenFile = (e, id) => {
		e.preventDefault();
		const fileInput = document.getElementById(id);
		fileInput.click();
	};
	handleFileChangeAndSubmit = async (event, submit, fileList) => {
		event.preventDefault();

		if (event.target.name !== undefined || fileList) {
			let location;
			let file;
			if (event.target.name || fileList) {
				location = event.target.name ? event.target.files : fileList;
				file = location[0];
			}

			let fileType = file.type.split('/').shift();
			let mimetype = file.type;

			if (fileType === 'audio' && file.size > '536,870,912') {
				this.setState({
					exceededFileSize: t('less_than_512_mb'),
					fileDragOver: false,
				});
			} else if (fileType === 'audio') {
				const blobUrl = URL.createObjectURL(file);
				const fileName = trimFileName(file.name);
				if (this.props.data.deletedAudioFile) {
					this.props.clearAudioFile();
				}
				this.setState({
					stateFileToUpload: blobUrl,
					fileType: fileType,
					mimetype: mimetype,
					fileFormData: file,
					fileName: fileName,
					fileDragOver: false,
					exceededFileSize: '',
					fileErrors: '',
					deletedFileFormData: '',
					deletedFileName: '',
					deletedStateFileToUpload: '',
					deletedFileType: '',
					setUndoDeletedFile: false,
				});

				event.target.value = '';
				URL.revokeObjectURL(location[0]);
			} else {
				this.setState({
					fileErrors: t('suported_files'),
					fileDragOver: false,
				});
			}
		}
	};

	setDragOver = (bool) => {
		if (this.state.fileDragOver !== bool) {
			this.setState({ fileDragOver: bool });
		}
	};
	clearFileErrors = () => {
		this.setState({
			fileErrors: '',
		});
	};

	clearLetterErrors = () => {
		this.props.clearAllLetterErrros();
	};

	// handleAutocomplete = () => {
	// 	this.setState((prevState) => ({
	// 		autocompleteOpen: !prevState.autocompleteOpen,
	// 	}));
	// };
	render() {
		const {
			panel,
			letterCollection,
			customTopics,
			checklist,
			keyContacts,
			contacts,
			importantDocuments,
			personalInformation,
			devicePasswords,
			mediaPasswords,
			pets,
			assets,
			heirlooms,
			owed,
			recipientEmail,
			recipientType,
			emailErrors,
			queryField,
			showUsers,
			showFollowers,
			showFollowing,
			expanded,
			goodbyeMessage,
			funeralArrangements,
			lifePreferences,
			letterOrder,
			releaseNow,
			confirmationDialog,
			letterConfirmationDialog,
			newLetter,
			newLetterConfirmationDialog,
			editLetterId,
			openedDeleteLetterId,
			title,
			changedTitles,
			readLetter,
			setUndoDeletedFile,
			stateFileToUpload,
			fileDragOver,
			fileType,
			deletedFileName,
			fileName,
			exceededFileSize,
			fileErrors,
			animateLetterId,
			updateLetter,
		} = this.state;
		const {
			data: {
				loadingLetterRecipientsData,
				errorLoadingLetterRecipientsData,
				loadingLetterSendersData,
				errorLoadingLetterSendersData,
				letterSendersData,
				letterRecipients,
				emailRecipients,
				dateTimer: { dateValue },
				updatingGoodbyeLetter,
				errorUpdatingGoodbyeLetter,
				loadingGoodbyeLetter,
				errorLoadingGoodbyeLetter,
				deletingGoodbyeLetter,
				errorDeletingGoodbyeLetter,
				goodbyeLetter,
				loadingReceivedGoodbyeLetter,
				receivedGoodbyeLetter,
				errorLoadingReceivedGoodbyeLetter,
				editedAudioFile,
				deletedAudioFile,
				deletedEditedAudioFileToUpload,
				uploadingLetterAudioProgress,
				errorUploadingLetterAudio,
				// editedAudioFileToUpload,
			},
			UI: { mode },
			user: {
				authenticated,
				credentials: {
					handle,
					name,
					imageUrl,
					followerCount,
					followingCount,
					// letterEmails,
					// letterUserIds,
					letters,
					unreleasedLetters,
					canRecoverLetter,
					lettersMadeToday,
				},
			},
		} = this.props;

		return (
			<div>
				<Helmet>
					<title>Goodbye Letter - Goodbye App</title>
				</Helmet>

				{!handle ? (
					<Navigate to='/feed' />
				) : (
					<div className='skyBackgroundFill'>
						<Media
							queries={{
								isMobile: '(max-width: 1064px)',
								semiSmall: '(max-width: 600px)',
								verySmall: '(max-width: 340px)',
							}}
						>
							{(matches) =>
								!authenticated ? (
									<Navigate to='/login' />
								) : (
									<div>
										<RefreshSite />
										<div className='navbarContent'>
											<div className='flex alignItemsCenter'>
												<div className='backgroundLogo mgX'>
													<img className='logo' src={logo} alt='Logo' />
												</div>
												{!matches.isMobile && (
													<img
														className='navbarGBLogo'
														src={gbaLogo}
														alt='Goodbye App'
													/>
												)}
												<span className='titleToolbar mglH'>{t('letter')}</span>
											</div>
											<ConfirmationDialog
												open={confirmationDialog}
												openFunc={this.handleDialog}
												closeFunc={this.handleDialog}
												openIconOrString={<CloseIcon id='closeDialogButton' />}
												actionBtnText={t('exit_without_saving')}
												customButtonId='customYellowButton'
												disableRipple
												variant='none'
												actionBtnMargin='1rem 0'
												cancelBtnText={t('continue_editing')}
												action={this.close}
												startIcon={<LogoutIcon />}
												phrase={t('exit')}
												contentTitle={t('all_changes_will_be_lost')}
												cancelBtnIcon={<EditIcon />}
												list={
													<div className='pdl'>
														<p className='mgbOnly'>{t('edited_fields')}</p>
														<ul>
															{changedTitles.map((topic, index) => {
																return (
																	<li key={index} className='text'>
																		{topic}
																	</li>
																);
															})}
														</ul>
													</div>
												}
											/>
											{newLetterConfirmationDialog && (
												<ConfirmationDialog
													iconButton
													open={newLetterConfirmationDialog}
													openFunc={() => this.handleAddLetter(true)}
													closeFunc={() => this.handleAddLetter(false)}
													actionBtnText={t('new')}
													customButtonId='customInfoButton'
													disableRipple
													cancelBtnText={t('continue_editing')}
													action={() => this.handleAddLetter(true, 'add')}
													startIcon={<HistoryEduIcon />}
													phrase={t('unsaved_changes')}
													phrase2={t('save_letter_warning_2')}
													list={
														<div id='mgl'>
															<p>{t('edited_fields')}</p>
															<ul className='bold'>
																{changedTitles.map((topic, index) => {
																	return <li key={index}>{topic}</li>;
																})}
															</ul>
														</div>
													}
												/>
											)}
										</div>

										<div className='pdAccordion'>
											<div className={`cardLetter ${mode === 'dark' && 'dbc'}`}>
												<CustomAlerts
													error={errorDeletingGoodbyeLetter}
													message={t('error_deleting_content', {
														content: t('letter').toLowerCase(),
													})}
													close
													noMargin
													alertId='smallMuiAlert'
												/>
												<CustomAlerts
													info={deletingGoodbyeLetter}
													message={t('deleting')}
													noMargin
													alertId='smallMuiAlert'
												/>
												<div style={dim(deletingGoodbyeLetter)}>
													{loadingGoodbyeLetter && <LinearProgress />}
													<CustomAlerts
														error={errorLoadingGoodbyeLetter}
														close
														noMargin
														alertId='smallMuiAlert'
														onClose={this.clearLetterErrors}
													/>

													<div>
														{loadingGoodbyeLetter || deletingGoodbyeLetter ? (
															<Skeleton
																variant='rectangular'
																width='80%'
																height='50px'
																animation='wave'
																sx={{ margin: '1rem auto' }}
															/>
														) : (
															<div className='flex center fullWidth pdt08'>
																<Tabs
																	value={panel}
																	onChange={this.handlePanelChange}
																	selectionFollowsFocus
																	scrollButtons='auto'
																	allowScrollButtonsMobile
																	variant='scrollable'
																>
																	<Tab
																		id='tabs'
																		sx={{
																			minWidth: matches.isMobile ? '' : '120px',
																		}}
																		icon={<InfoOutlinedIcon />}
																		iconPosition='top'
																		label={t('intro')}
																		value='intro'
																	/>
																	<Tab
																		id='tabs'
																		sx={{
																			minWidth: matches.isMobile ? '' : '120px',
																		}}
																		icon={<HistoryEduIcon />}
																		iconPosition='top'
																		label={t('letters')}
																		value='letters'
																	/>
																	{((newLetter &&
																		Object.keys(goodbyeLetter).length === 0 &&
																		letters &&
																		letters.length + unreleasedLetters.length <
																			10) ||
																		(Object.keys(goodbyeLetter).length > 0 &&
																			unreleasedLetters.findIndex(
																				(obj) =>
																					obj.letterId ===
																					goodbyeLetter.letterId,
																			) !== -1 &&
																			!goodbyeLetter.sent &&
																			editLetterId ===
																				goodbyeLetter.letterId)) && (
																		<Tab
																			id='tabs'
																			sx={{
																				minWidth: matches.isMobile
																					? ''
																					: '120px',
																			}}
																			icon={<CreateIcon />}
																			iconPosition='top'
																			label={t('write')}
																			value='write'
																		/>
																	)}

																	{((newLetter &&
																		Object.keys(goodbyeLetter).length === 0 &&
																		letters &&
																		letters.length + unreleasedLetters.length <
																			10) ||
																		(Object.keys(goodbyeLetter).length > 0 &&
																			editLetterId ===
																				goodbyeLetter.letterId)) && (
																		<Tab
																			id='tabs'
																			sx={{
																				minWidth: matches.isMobile
																					? ''
																					: '120px',
																			}}
																			icon={<PeopleIcon />}
																			iconPosition='top'
																			label={t('send_to')}
																			value='sendTo'
																		/>
																	)}
																	{((newLetter &&
																		Object.keys(goodbyeLetter).length === 0 &&
																		letters &&
																		letters.length + unreleasedLetters.length <
																			10) ||
																		(Object.keys(goodbyeLetter).length > 0 &&
																			unreleasedLetters.findIndex(
																				(obj) =>
																					obj.letterId ===
																					goodbyeLetter.letterId,
																			) !== -1 &&
																			!goodbyeLetter.sent &&
																			editLetterId ===
																				goodbyeLetter.letterId)) && (
																		<Tab
																			id='tabs'
																			sx={{
																				minWidth: matches.isMobile
																					? ''
																					: '120px',
																			}}
																			icon={<QueryBuilderIcon />}
																			iconPosition='top'
																			label={t('send_on')}
																			value='sendOn'
																		/>
																	)}
																	{((newLetter &&
																		Object.keys(goodbyeLetter).length === 0) ||
																		// &&
																		// letters &&
																		// letters.length + unreleasedLetters.length <
																		// 	10

																		(Object.keys(goodbyeLetter).length > 0 &&
																			editLetterId ===
																				goodbyeLetter.letterId) ||
																		loadingGoodbyeLetter ||
																		updatingGoodbyeLetter) && (
																		<Tab
																			id='tabs'
																			sx={{
																				minWidth: matches.isMobile
																					? ''
																					: '120px',
																			}}
																			icon={<VisibilitySharpIcon />}
																			iconPosition='top'
																			label={t('preview')}
																			value='preview'
																		/>
																	)}
																</Tabs>
															</div>
														)}

														{panel === 'intro' ? (
															<div className='pd'>
																<Avatar
																	id='cardMainIcon'
																	className='flexAuto mgt'
																>
																	<HistoryEduIcon
																		id='cardMainIconSize'
																		className='leftTranslate2'
																	/>
																</Avatar>
																<p className='subtitle center mgtOnly'>
																	{t('letter_subtitle')}
																</p>

																<p>{t('letter_desc_1')}</p>
																<p>{t('letter_desc_2')}</p>
																<Divider textAlign='left' className='bold'>
																	{t('advice')}
																</Divider>
																<ul className='text'>
																	<li className='mgb'>
																		{t('letter_advice_1')}
																	</li>
																	<li>{t('letter_advice_2')}</li>
																</ul>
																<Divider textAlign='left' className='bold'>
																	{t('important_disclaimer')}
																</Divider>
																<p>{t('letter_important_disclaimer_1')}</p>
																<p className='mg0'>
																	{t('letter_important_disclaimer_2')}
																</p>
																<div className='center'>
																	<CustomButton
																		btnText={t('begin')}
																		onClick={() => this.openPanel('letters')}
																		variant='contained'
																		id='customCancelButton'
																		margin='2rem auto 1rem'
																		startIcon={<HistoryEduIcon />}
																	/>
																</div>
															</div>
														) : panel === 'write' ? (
															<div>
																<h2 className='mg textTitle flex leftTranslate2 alignItemsCenter'>
																	<EmojiObjectsSharpIcon className='mgrH' />{' '}
																	{t('topics_fill_as_intended')}
																	<small className='mgl4px'>
																		{goodbyeLetter.edited > 0
																			? ` (${goodbyeLetter.edited} ${
																					goodbyeLetter.edited === 1
																						? t('edition')
																						: t('editions')
																			  })`
																			: ''}
																	</small>
																</h2>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'goodbyeMessage'}
																	onChange={() =>
																		this.changePanel('goodbyeMessage')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='goodbyeMessage'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<CreateIcon
																				fontSize='small'
																				className='mgrH'
																			/>{' '}
																			{t('goodbye_message')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('goodbye_message_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('goodbye_message_desc_1')}
																			</p>
																		)}

																		<p className='mgbOnly'>
																			{t('goodbye_message_desc_2')}{' '}
																			{t('advice')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('advice')}
																				className='topTranslate3'
																				content={
																					<div className='pd'>
																						<p className='mg0 mgb'>
																							{t('goodbye_message_advice_1')}
																						</p>
																						<p className='mg0'>
																							{t('goodbye_message_advice_2')}
																						</p>
																					</div>
																				}
																			/>
																		</p>

																		<CustomInput
																			variant='outlined'
																			placeholder={t('type_here')}
																			name='goodbyeMessage'
																			inputId='goodbyeMessage'
																			inputValue={goodbyeMessage}
																			onChange={(e) => this.handleChange(e)}
																			fullWidth
																			maxLength={5000}
																			helperText={
																				goodbyeMessage.length >= 500
																					? `${goodbyeMessage.length}/5000`
																					: ''
																			}
																			minRows={3}
																			multiline
																		/>

																		<div className='center'>
																			<CustomButton
																				endIcon={<KeyboardArrowDownIcon />}
																				btnText={t('next')}
																				margin='1rem auto 0'
																				onClick={() =>
																					this.fillNextTopic('checklist')
																				}
																			/>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'checklist'}
																	onChange={() => this.changePanel('checklist')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='checklist'
																	>
																		<Typography
																			className='flex notranslate'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<ChecklistRtlIcon
																				id='iconAccordion'
																				className='iconGreen bottomTranslate'
																			/>{' '}
																			{t('checklist')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('checklist_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('checklist_desc_1')}
																			</p>
																		)}
																		<p className='mgbOnly'>
																			{t('checklist_desc_2')} {t('examples')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>{t('checklist_example_1')}</li>
																						<li>{t('checklist_example_2')}</li>
																						<li>{t('checklist_example_3')}</li>
																					</ul>
																				}
																			/>
																		</p>

																		<ReactSortable
																			handle='.swapDrag'
																			list={checklist}
																			disabled={checklist.length < 2}
																			setList={(newState) =>
																				this.setState({ checklist: newState })
																			}
																			swap
																			animation='200'
																			swapThreshold='3'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{checklist.map((item, index) => (
																				<ListItem
																					disablePadding
																					key={item.id}
																					className='grayBorders flex'
																				>
																					<div className='gbChecklist pd pdlH'>
																						{checklist.length > 1 && (
																							<ListItemIcon
																								className='swapDrag'
																								sx={{
																									minWidth: '0px',
																								}}
																							>
																								<SwapVertSharpIcon id='swapReorderIcon' />
																							</ListItemIcon>
																						)}
																						<ListItemIcon
																							className='swapDrag'
																							sx={{
																								minWidth: '0px',
																							}}
																						>
																							<Checkbox
																								checked={true}
																								edge='start'
																								tabIndex={-1}
																								sx={{
																									minWidth: '0px',
																									margin: '0 0.3rem 0 0',
																								}}
																							/>
																						</ListItemIcon>

																						<CustomInput
																							variant='standard'
																							placeholder={t('type_here')}
																							inputValue={item.content}
																							inputId={`checklistInput_${index}`}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'content',
																									'checklist',
																								)
																							}
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.content.length >= 250
																									? `${item.content.length}/500`
																									: ''
																							}
																							multiline
																						/>

																						<CustomButton
																							iconButton
																							onClick={() =>
																								this.handleRemove(
																									index,
																									'checklist',
																								)
																							}
																							margin='0 -0.5rem 0 0.5rem'
																							tooltip={t('delete')}
																							className='sortable-drag'
																							placement='top'
																							startIcon={
																								<DeleteOutlineSharpIcon id='red' />
																							}
																						/>
																					</div>
																				</ListItem>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{checklist.length < 15 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'checklist',
																								maxItems: 15,
																								errorMessage: t('cant_exceed', {
																									number: 15,
																									content: t('checklists'),
																								}),
																								target: `checklistInput_${checklist.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('keyContacts')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'keyContacts'}
																	onChange={() =>
																		this.changePanel('keyContacts')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='keyContacts'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<ContactPhoneIcon
																				className='iconYellow'
																				id='iconAccordion'
																			/>{' '}
																			{t('key_contacts')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('key_contacts_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('key_contacts_desc_1')}
																			</p>
																		)}
																		<p className='mgbOnly'>
																			{t('key_contacts_desc_2')} {t('examples')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>
																							{t('key_contacts_example_1')}
																						</li>
																						<li>
																							{t('key_contacts_example_2')}
																						</li>
																						<li>
																							{t('key_contacts_example_3')}
																						</li>
																						<li>
																							{t('key_contacts_example_4')}
																						</li>
																						<li>
																							{t('key_contacts_example_5')}
																						</li>
																						<li>
																							{t('key_contacts_example_6')}
																						</li>
																						<li>
																							{t('key_contacts_example_7')}
																						</li>
																					</ul>
																				}
																			/>
																		</p>

																		<ReactSortable
																			handle='.swapDrag'
																			list={keyContacts}
																			disabled={keyContacts.length < 2}
																			setList={(newState) =>
																				this.setState({ keyContacts: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{keyContacts.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{keyContacts.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								inputValue={item.name}
																								inputId={`keyContactsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'name',
																										'keyContacts',
																									)
																								}
																								fullWidth
																								maxLength={50}
																								helperText={
																									item.name.length >= 25 &&
																									`${item.name.length}/50`
																								}
																								label={t('contact')}
																								multiline
																								margin='0'
																								// inputId={item.id}
																							/>

																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'keyContacts',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>

																					<hr className='mg0' />
																					<div className='pd'>
																						<CustomInput
																							variant='outlined'
																							placeholder={t('type_here')}
																							inputValue={item.content}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'content',
																									'keyContacts',
																								)
																							}
																							fullWidth
																							maxLength={500}
																							margin='0 0 1rem 0'
																							helperText={
																								item.content.length >= 250 &&
																								`${item.content.length}/500`
																							}
																							label={t('relation_to_me')}
																							multiline
																						/>
																						<CustomInput
																							label={t('phone_number')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='phoneNumber'
																							type='tel'
																							autoComplete='tel-national'
																							maxLength={15}
																							multiline
																							fullWidth
																							inputValue={item.number}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'number',
																									'keyContacts',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{keyContacts.length < 15 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'keyContacts',
																								maxItems: 15,
																								errorMessage: t('cant_exceed', {
																									number: 15,
																									content:
																										t(
																											'key_contacts',
																										).toLowerCase(),
																								}),
																								target: `keyContactsInput_${keyContacts.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('contacts')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'contacts'}
																	onChange={() => this.changePanel('contacts')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='contacts'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<Groups3Icon
																				id='iconAccordion'
																				className='iconOrange'
																			/>{' '}
																			{t('secondary_contacts')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('secondary_contacts_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('secondary_contacts_desc_1')}
																			</p>
																		)}
																		<p className='mgbOnly'>
																			{t('secondary_contacts_desc_2')}
																		</p>

																		<ReactSortable
																			handle='.swapDrag'
																			list={contacts}
																			disabled={contacts.length < 2}
																			setList={(newState) =>
																				this.setState({ contacts: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{contacts.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{contacts.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								inputValue={item.name}
																								inputId={`contactsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'name',
																										'contacts',
																									)
																								}
																								fullWidth
																								maxLength={50}
																								helperText={
																									item.name.length >= 25 &&
																									`${item.name.length}/50`
																								}
																								label={t('contact')}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'contacts',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />
																					<div className='pd'>
																						<CustomInput
																							variant='outlined'
																							placeholder={t('type_here')}
																							inputValue={item.content}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'content',
																									'contacts',
																								)
																							}
																							fullWidth
																							maxLength={500}
																							label={t('relation_to_me')}
																							helperText={
																								item.content.length >= 250 &&
																								`${item.content.length}/500`
																							}
																							multiline
																							margin='0 0 1rem 0'
																						/>
																						<CustomInput
																							label={t('phone_number')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='phoneNumber'
																							type='tel'
																							autoComplete='tel-national'
																							maxLength={15}
																							fullWidth
																							multiline
																							inputValue={item.number}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'number',
																									'contacts',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{contacts.length < 15 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'contacts',
																								maxItems: 15,
																								errorMessage: t('cant_exceed', {
																									number: 15,
																									content:
																										t('contacts').toLowerCase(),
																								}),
																								target: `contactsInput_${contacts.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic(
																							'importantDocuments',
																						)
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'importantDocuments'}
																	onChange={() =>
																		this.changePanel('importantDocuments')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='importantDocuments'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<FolderIcon
																				id='iconAccordion'
																				className='iconLightGray'
																			/>{' '}
																			{t('location_importants_documents')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t(
																					'location_importants_documents_desc_1',
																				)}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t(
																					'location_importants_documents_desc_1',
																				)}
																			</p>
																		)}
																		<p className='mgbOnly'>
																			{t(
																				'location_importants_documents_desc_2',
																			)}{' '}
																			{t('examples')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>{t('birth_certificate')}</li>
																						<li>{t('will')}</li>
																						<li>{t('power_of_attorney')}</li>
																						<li>{t('life_insurance')}</li>
																						<li>
																							{t('vehicle_ownership_papers')}
																						</li>
																						<li>{t('passport')}</li>
																						<li>
																							{t(
																								'marriage_divorce_certificates',
																							)}
																						</li>
																						<li>{t('password_logbook')}</li>
																					</ul>
																				}
																			/>
																		</p>

																		<ul className='bold'></ul>

																		<ReactSortable
																			handle='.swapDrag'
																			list={importantDocuments}
																			disabled={importantDocuments.length < 2}
																			setList={(newState) =>
																				this.setState({
																					importantDocuments: newState,
																				})
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{importantDocuments.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{importantDocuments.length >
																								1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}
																							<CustomInput
																								variant='standard'
																								label={t('document_type')}
																								placeholder={t('type_here')}
																								inputValue={item.type}
																								inputId={`importantDocumentsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'type',
																										'importantDocuments',
																									)
																								}
																								fullWidth
																								maxLength={300}
																								helperText={
																									item.type.length >= 150 &&
																									`${item.type.length}/300`
																								}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'importantDocuments',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />
																					<div className='pd'>
																						<CustomInput
																							label={t('located_at')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='location'
																							multiline
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.location.length >= 250 &&
																								`${item.location.length}/500`
																							}
																							margin='0 0 1rem 0'
																							inputValue={item.location}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'location',
																									'importantDocuments',
																								)
																							}
																						/>
																						<CustomInput
																							label={t(
																								'additional_instructions',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							helperText={`${item.additional.length}/500`}
																							maxLength={500}
																							inputValue={item.additional}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'additional',
																									'importantDocuments',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{importantDocuments.length < 10 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'importantDocuments',
																								maxItems: 10,
																								errorMessage: t('cant_exceed', {
																									number: 10,
																									content:
																										t(
																											'documents',
																										).toLowerCase(),
																								}),
																								target: `importantDocumentsInput_${importantDocuments.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic(
																							'personalInformation',
																						)
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'personalInformation'}
																	onChange={() =>
																		this.changePanel('personalInformation')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='personalInformation'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<InfoIcon
																				id='iconAccordion'
																				className=' iconBlue'
																			/>{' '}
																			{t('personal_information')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('personal_information_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('personal_information_desc_1')}
																			</p>
																		)}
																		{personalInformation.map((item, index) => (
																			<div
																				key={item.id}
																				className='grayBorders'
																			>
																				<ListItem disablePadding>
																					<div className='gbChecklist pd'>
																						{personalInformation.length > 1 && (
																							<ListItemIcon
																								className='swapDrag'
																								sx={{
																									minWidth: '40px',
																								}}
																							>
																								<SwapVertSharpIcon id='swapReorderIcon' />
																							</ListItemIcon>
																						)}

																						<CustomInput
																							variant='standard'
																							type='name'
																							label={t('full_name')}
																							placeholder={t('type_here')}
																							inputValue={item.fullName}
																							inputId={`personalInformationInput_${index}`}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'fullName',
																									'personalInformation',
																								)
																							}
																							fullWidth
																							maxLength={100}
																							helperText={
																								item.fullName.length >= 50 &&
																								`${item.fullName.length}/100`
																							}
																							multiline
																							margin='0'
																						/>

																						<CustomButton
																							iconButton
																							onClick={() =>
																								this.handleRemove(
																									index,
																									'personalInformation',
																								)
																							}
																							margin='0 -0.5rem 0 0.5rem'
																							tooltip={t('delete')}
																							className='sortable-drag'
																							placement='top'
																							startIcon={
																								<DeleteOutlineSharpIcon id='red' />
																							}
																						/>
																					</div>
																				</ListItem>
																				<hr className='mg0' />
																				<div className='pd'>
																					<CustomInput
																						label={t('address')}
																						placeholder={t('type_here')}
																						useLocation
																						variant='outlined'
																						startIconType='location'
																						margin='6px 0 14px 0'
																						multiline
																						fullWidth
																						maxLength={300}
																						helperText={
																							item.address.length >= 150 &&
																							`${item.address.length}/300`
																						}
																						inputValue={item.address}
																						onChange={(e) =>
																							this.handleInputChange(
																								e,
																								index,
																								'address',
																								'personalInformation',
																							)
																						}
																					/>
																					<div className='flexColumn mgb'>
																						<DateAndTimePicker
																							onlyPicker
																							setDate={(value) =>
																								this.handleInputChange(
																									'',
																									index,
																									'birthDate',
																									'personalInformation',
																									value,
																								)
																							}
																						/>
																					</div>
																					<CustomInput
																						label={t('mother_full_name')}
																						placeholder={t('type_here')}
																						variant='outlined'
																						fullWidth
																						maxLength={100}
																						helperText={
																							item.mothersFullName.length >=
																								50 &&
																							`${item.mothersFullName.length}/100`
																						}
																						multiline
																						inputValue={item.mothersFullName}
																						onChange={(e) =>
																							this.handleInputChange(
																								e,
																								index,
																								'mothersFullName',
																								'personalInformation',
																							)
																						}
																						margin='0 0 1rem 0'
																					/>

																					<CustomInput
																						multiline
																						label={t('father_full_name')}
																						placeholder={t('type_here')}
																						variant='outlined'
																						fullWidth
																						maxLength={100}
																						helperText={
																							item.fathersFullName.length >=
																								50 &&
																							`${item.fathersFullName.length}/100`
																						}
																						inputValue={item.fathersFullName}
																						onChange={(e) =>
																							this.handleInputChange(
																								e,
																								index,
																								'fathersFullName',
																								'personalInformation',
																							)
																						}
																						margin='0 0 1rem 0'
																					/>

																					<CustomInput
																						label={t('additional')}
																						placeholder={t('type_here')}
																						variant='outlined'
																						multiline
																						fullWidth
																						helperText={`${item.additional.length}/500`}
																						maxLength={500}
																						inputValue={item.additional}
																						onChange={(e) =>
																							this.handleInputChange(
																								e,
																								index,
																								'additional',
																								'personalInformation',
																							)
																						}
																					/>
																				</div>
																			</div>
																		))}
																		<div className='flex'>
																			{personalInformation.length === 0 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'personalInformation',
																								maxItems: 1,
																								errorMessage: t('cant_exceed', {
																									number: 1,
																									content: t('document'),
																								}),
																								target: `personalInformationInput_${personalInformation.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('passwords')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'passwords'}
																	onChange={() => this.changePanel('passwords')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='passwords'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<PasswordIcon id='iconAccordion' />{' '}
																			{t('passwords')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('passwords_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('passwords_desc_1')}
																			</p>
																		)}
																		<CustomAlerts
																			info={true}
																			icon={<HttpsIcon />}
																			message={t('passwords_desc_2')}
																			margin='0'
																			noCenter
																			alertId='smallMuiAlert'
																		/>

																		<p className='bold'>
																			{t('passwords_desc_3')}
																		</p>
																		<p>{t('passwords_desc_4')}</p>
																		<div
																			tabIndex={0}
																			id='devicePasswords'
																		></div>
																		<ReactSortable
																			handle='.swapDrag'
																			list={devicePasswords}
																			disabled={devicePasswords.length < 2}
																			setList={(newState) =>
																				this.setState({
																					devicePasswords: newState,
																				})
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{devicePasswords.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{devicePasswords.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								inputValue={item.device}
																								inputId={`devicePasswordsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'device',
																										'devicePasswords',
																									)
																								}
																								fullWidth
																								maxLength={100}
																								helperText={
																									item.device.length >= 50 &&
																									`${item.device.length}/100`
																								}
																								label={t('device')}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'devicePasswords',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />
																					<div className='pd'>
																						<CustomInput
																							label={t('located_at')}
																							variant='outlined'
																							placeholder={t('type_here')}
																							startIconType='location'
																							multiline
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.location.length >= 250 &&
																								`${item.location.length}/500`
																							}
																							inputValue={item.location}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'location',
																									'devicePasswords',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<CustomInput
																							variant='filled'
																							label={t('password_pattern')}
																							placeholder={t('type_here')}
																							fullWidth
																							maxLength={100}
																							multiline={false}
																							inputValue={item.password}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'password',
																									'devicePasswords',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		{devicePasswords.length < 10 && (
																			<div className='center'>
																				<CustomButton
																					startIcon={
																						<AddCircleOutlineSharpIcon />
																					}
																					btnText={t('add')}
																					margin='1rem 0 0 0'
																					onClick={() =>
																						this.handleAdd({
																							type: 'devicePasswords',
																							maxItems: 10,
																							errorMessage: t('cant_exceed', {
																								number: 10,
																								content: t('documents'),
																							}),
																							target: `devicePasswordsInput_${devicePasswords.length}`,
																						})
																					}
																				/>
																			</div>
																		)}
																		<div tabIndex={0} id='mediaPasswords'></div>
																		<p>{t('passwords_desc_5')}</p>
																		<ReactSortable
																			handle='.swapDrag'
																			list={mediaPasswords}
																			disabled={mediaPasswords.length < 2}
																			setList={(newState) =>
																				this.setState({
																					mediaPasswords: newState,
																				})
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{mediaPasswords.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{mediaPasswords.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								inputValue={item.website}
																								inputId={`mediaPasswordsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'website',
																										'mediaPasswords',
																									)
																								}
																								fullWidth
																								maxLength={100}
																								helperText={
																									item.website.length >= 50 &&
																									`${item.website.length}/100`
																								}
																								label={t('application_website')}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'mediaPasswords',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />
																					<div className='pd'>
																						<CustomInput
																							label={t(
																								'password_logbook_location',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='location'
																							multiline
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.location.length >= 250 &&
																								`${item.location.length}/500`
																							}
																							inputValue={item.location}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'location',
																									'mediaPasswords',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{mediaPasswords.length < 10 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'mediaPasswords',
																								maxItems: 10,
																								errorMessage: t('cant_exceed', {
																									number: 10,
																									content: t('documents'),
																								}),
																								target: `mediaPasswordsInput_${mediaPasswords.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('pets')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'pets'}
																	onChange={() => this.changePanel('pets')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='pets'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<PetsIcon
																				id='iconAccordion'
																				className='iconViolet'
																			/>{' '}
																			{t('pets')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>{t('pets_desc_1')}</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('pets_desc_1')}
																			</p>
																		)}

																		<CustomAlerts
																			info={true}
																			icon={<AccountBoxIcon />}
																			message={t('pets_desc_2')}
																			margin='0'
																			noCenter
																			alertId='smallMuiAlert'
																		/>
																		<p>{t('pets_desc_3')}</p>
																		{/* <div tabIndex={0} id='pets'></div> */}
																		<ReactSortable
																			handle='.swapDrag'
																			list={pets}
																			disabled={pets.length < 2}
																			setList={(newState) =>
																				this.setState({ pets: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{pets.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{pets.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								type='name'
																								inputValue={item.name}
																								inputId={`petsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'name',
																										'pets',
																									)
																								}
																								fullWidth
																								maxLength={100}
																								helperText={
																									item.name.length >= 50 &&
																									`${item.name.length}/100`
																								}
																								label={t('pet_name')}
																								multiline
																								placeholder={t('type_here')}
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'pets',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />

																					<div className='pd'>
																						<CustomInput
																							label={t('pet_diet')}
																							variant='outlined'
																							multiline
																							placeholder={t('type_here')}
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.food.length >= 250 &&
																								`${item.food.length}/500`
																							}
																							inputValue={item.food}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'food',
																									'pets',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<CustomInput
																							label={t(
																								'medication_and_allegies',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							inputValue={
																								item.medicationAndAllergies
																							}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'medicationAndAllergies',
																									'pets',
																								)
																							}
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.medicationAndAllergies
																									.length >= 250 &&
																								`${item.medicationAndAllergies.length}/500`
																							}
																							multiline
																							margin='0 0 1rem 0'
																						/>
																						<div className='dotted' />

																						<CustomInput
																							label={t('caregiver')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							maxLength={100}
																							helperText={
																								item.emergencyCaregiver.length >
																									50 &&
																								`${item.emergencyCaregiver.length}/100`
																							}
																							inputValue={
																								item.emergencyCaregiver
																							}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'emergencyCaregiver',
																									'pets',
																								)
																							}
																							margin='1rem 0 1rem 0'
																						/>

																						<CustomInput
																							label={t('caregiver_number')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='phoneNumber'
																							type='tel'
																							autoComplete='tel-national'
																							maxLength={15}
																							multiline
																							fullWidth
																							inputValue={
																								item.emergencyCaregiverNumber
																							}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'emergencyCaregiverNumber',
																									'pets',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<div className='dotted' />

																						<CustomInput
																							label={t('veterinarian_name')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							maxLength={100}
																							helperText={
																								item.vetName.length >= 50 &&
																								`${item.vetName.length}/100`
																							}
																							inputValue={item.vetName}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'vetName',
																									'pets',
																								)
																							}
																							margin='1rem 0'
																						/>

																						<CustomInput
																							label={t('veterinarian_number')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='phoneNumber'
																							type='tel'
																							autoComplete='tel-national'
																							maxLength={15}
																							multiline
																							fullWidth
																							inputValue={item.vetNumber}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'vetNumber',
																									'pets',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<div className='dotted' />

																						<CustomInput
																							label={t(
																								'additional_instructions',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							helperText={`${item.additional.length}/500`}
																							maxLength={500}
																							inputValue={item.additional}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'additional',
																									'pets',
																								)
																							}
																							margin='1rem 0 0 0'
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{pets.length < 5 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'pets',
																								maxItems: 5,
																								errorMessage: t('cant_exceed', {
																									number: 5,
																									content: t('documents'),
																								}),
																								target: `petsInput_${pets.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('assets')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<p className='mg textTitle flex'>
																	<VolunteerActivismSharpIcon className='mgrH bottomTranslate3' />{' '}
																	{t('your_preferences_distribution')}
																</p>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'assets'}
																	onChange={() => this.changePanel('assets')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='assets'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<DirectionsCarSharpIcon
																				id='iconAccordion'
																				className='iconLightBlue'
																			/>{' '}
																			{t('vehicle_and_assets')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('vehicle_and_assets_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('vehicle_and_assets_desc_1')}
																			</p>
																		)}

																		<CustomAlerts
																			info={true}
																			icon={<GavelIcon />}
																			message={t('letter_disclaimer')}
																			margin='0'
																			noCenter
																			alertId='smallMuiAlert'
																		/>

																		<p>{t('can_include_assets')}</p>

																		<ReactSortable
																			handle='.swapDrag'
																			list={assets}
																			disabled={assets.length < 2}
																			setList={(newState) =>
																				this.setState({ assets: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{assets.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{assets.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								label={t('asset')}
																								inputValue={item.asset}
																								inputId={`assetsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'asset',
																										'assets',
																									)
																								}
																								fullWidth
																								maxLength={500}
																								helperText={
																									item.asset.length >= 250 &&
																									`${item.asset.length}/500`
																								}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'assets',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />

																					<div className='pd'>
																						<CustomInput
																							label={t('geographic_location')}
																							placeholder={t('type_here')}
																							useLocation
																							variant='outlined'
																							startIconType='location'
																							multiline
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.location.length >= 250 &&
																								`${item.location.length}/500`
																							}
																							inputValue={item.location}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'location',
																									'assets',
																								)
																							}
																							margin='6px 0 14px 0'
																						/>

																						<CustomInput
																							label={t('keys_location')}
																							variant='outlined'
																							placeholder={t('type_here')}
																							startIconType='keyLocation'
																							multiline
																							fullWidth
																							margin='0 0 1rem 0'
																							maxLength={300}
																							helperText={
																								item.keyLocation.length >=
																									150 &&
																								`${item.keyLocation.length}/300`
																							}
																							inputValue={item.keyLocation}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'keyLocation',
																									'assets',
																								)
																							}
																						/>
																						<CustomInput
																							label={t('title_deed_location')}
																							variant='outlined'
																							placeholder={t('type_here')}
																							startIconType='docLocation'
																							multiline
																							fullWidth
																							maxLength={300}
																							helperText={
																								item.importantDocuments.length >
																									150 &&
																								`${item.importantDocuments.length}/300`
																							}
																							inputValue={
																								item.importantDocuments
																							}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'importantDocuments',
																									'assets',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>
																						<CustomInput
																							label={t('action')}
																							placeholder={t(
																								'sell_or_donate_to',
																							)}
																							variant='outlined'
																							multiline
																							fullWidth
																							maxLength={300}
																							helperText={
																								item.action.length >= 150 &&
																								`${item.action.length}/300`
																							}
																							inputValue={item.action}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'action',
																									'assets',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>
																						<CustomInput
																							label={t(
																								'additional_instructions',
																							)}
																							variant='outlined'
																							placeholder={t('type_here')}
																							multiline
																							fullWidth
																							helperText={`${item.additional.length}/500`}
																							maxLength={500}
																							inputValue={item.additional}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'additional',
																									'assets',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{assets.length < 15 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'assets',
																								maxItems: 15,
																								errorMessage: t('cant_exceed', {
																									number: 15,
																									content: t('documents'),
																								}),
																								target: `assetsInput_${assets.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('heirlooms')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'heirlooms'}
																	onChange={() => this.changePanel('heirlooms')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='heirlooms'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<WatchSharpIcon
																				id='iconAccordion'
																				className='iconMidBlue'
																			/>
																			{t('personal_items_heirlooms')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('personal_items_heirlooms_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{/* <div tabIndex={0} id='heirlooms'></div> */}
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('personal_items_heirlooms_desc_1')}
																			</p>
																		)}

																		<p className='mgbOnly'>
																			{t('personal_items_heirlooms_desc_2')}{' '}
																			{t('examples')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>{t('heirlooms_example_1')}</li>
																						<li>{t('heirlooms_example_2')}</li>
																						<li>{t('heirlooms_example_3')}</li>
																						<li>{t('heirlooms_example_4')}</li>
																						<li>{t('heirlooms_example_5')}</li>
																						<li>{t('heirlooms_example_6')}</li>
																					</ul>
																				}
																			/>
																		</p>

																		<ReactSortable
																			handle='.swapDrag'
																			list={heirlooms}
																			disabled={heirlooms.length < 2}
																			setList={(newState) =>
																				this.setState({ heirlooms: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{heirlooms.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{heirlooms.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								label={t('item_type')}
																								inputValue={item.item}
																								inputId={`heirloomsInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'item',
																										'heirlooms',
																									)
																								}
																								fullWidth
																								maxLength={500}
																								helperText={
																									item.item.length >= 250 &&
																									`${item.item.length}/500`
																								}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'heirlooms',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />

																					<div className='pd'>
																						<CustomInput
																							label={t('item_location')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							startIconType='location'
																							multiline
																							fullWidth
																							maxLength={500}
																							helperText={
																								item.location.length >= 250 &&
																								`${item.location.length}/500`
																							}
																							inputValue={item.location}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'location',
																									'heirlooms',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<CustomInput
																							label={t('action')}
																							placeholder={t(
																								'sell_or_donate_to',
																							)}
																							variant='outlined'
																							multiline
																							fullWidth
																							maxLength={300}
																							helperText={
																								item.action.length >= 150 &&
																								`${item.action.length}/300`
																							}
																							inputValue={item.action}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'action',
																									'heirlooms',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<CustomInput
																							label={t(
																								'additional_instructions',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							helperText={`${item.additional.length}/500`}
																							maxLength={500}
																							inputValue={item.additional}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'additional',
																									'heirlooms',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{heirlooms.length < 15 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'heirlooms',
																								maxItems: 15,
																								errorMessage: t('cant_exceed', {
																									number: 15,
																									content: t('documents'),
																								}),
																								target: `heirloomsInput_${heirlooms.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic('owed')
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'owed'}
																	onChange={() => this.changePanel('owed')}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='owed'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<PaidSharpIcon
																				id='iconAccordion'
																				className='iconGreen'
																			/>{' '}
																			{t('loans_debts')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('loans_debts_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		{matches.isMobile && (
																			<p className='mgbOnly'>
																				{t('loans_debts_desc_1')}
																			</p>
																		)}
																		<p className='mgbOnly'>
																			{t('loans_debts_desc_2')}
																		</p>
																		{/* <div tabIndex={0} id='owed'></div> */}

																		<ReactSortable
																			handle='.swapDrag'
																			list={owed}
																			disabled={owed.length < 2}
																			setList={(newState) =>
																				this.setState({ owed: newState })
																			}
																			swap
																			animation='200'
																			easing='ease-out'
																			dragClass='sortable-drag'
																			ghostClass='ghost'
																			forceFallback={true}
																			onStart={() =>
																				document.body.classList.add('grabbing')
																			}
																			onEnd={() => {
																				document.body.classList.remove(
																					'grabbing',
																				);
																			}}
																		>
																			{owed.map((item, index) => (
																				<div
																					key={item.id}
																					className='grayBorders'
																				>
																					<ListItem disablePadding>
																						<div className='gbChecklist pd'>
																							{owed.length > 1 && (
																								<ListItemIcon
																									className='swapDrag'
																									sx={{
																										minWidth: '40px',
																									}}
																								>
																									<SwapVertSharpIcon id='swapReorderIcon' />
																								</ListItemIcon>
																							)}

																							<CustomInput
																								variant='standard'
																								placeholder={t('type_here')}
																								label={t('loan_type')}
																								inputValue={item.loanType}
																								inputId={`owedInput_${index}`}
																								onChange={(e) =>
																									this.handleInputChange(
																										e,
																										index,
																										'loanType',
																										'owed',
																									)
																								}
																								fullWidth
																								maxLength={500}
																								helperText={
																									item.loanType.length >= 250 &&
																									`${item.loanType.length}/500`
																								}
																								multiline
																								margin='0'
																							/>
																							<CustomButton
																								iconButton
																								onClick={() =>
																									this.handleRemove(
																										index,
																										'owed',
																									)
																								}
																								margin='0 -0.5rem 0 0.5rem'
																								tooltip={t('delete')}
																								className='sortable-drag'
																								placement='top'
																								startIcon={
																									<DeleteOutlineSharpIcon id='red' />
																								}
																							/>
																						</div>
																					</ListItem>
																					<hr className='mg0' />

																					<div className='pd '>
																						<CustomInput
																							label={t('lender')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							maxLength={300}
																							helperText={
																								item.lender.length >= 150 &&
																								`${item.lender.length}/300`
																							}
																							inputValue={item.lender}
																							margin='0 0 1rem 0'
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'lender',
																									'owed',
																								)
																							}
																						/>
																						<CustomInput
																							label={t('amount_owed')}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							startIconType='money'
																							maxLength={300}
																							helperText={
																								item.owed.length >= 150 &&
																								`${item.owed.length}/300`
																							}
																							inputValue={item.owed}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'owed',
																									'owed',
																								)
																							}
																							margin='0 0 1rem 0'
																						/>

																						<CustomInput
																							label={t(
																								'additional_instructions',
																							)}
																							placeholder={t('type_here')}
																							variant='outlined'
																							multiline
																							fullWidth
																							helperText={`${item.additional.length}/500`}
																							maxLength={500}
																							inputValue={item.additional}
																							onChange={(e) =>
																								this.handleInputChange(
																									e,
																									index,
																									'additional',
																									'owed',
																								)
																							}
																						/>
																					</div>
																				</div>
																			))}
																		</ReactSortable>
																		<div className='flex'>
																			{owed.length < 10 && (
																				<div className='center'>
																					<CustomButton
																						startIcon={
																							<AddCircleOutlineSharpIcon />
																						}
																						btnText={t('add')}
																						margin='1rem 0 0 0'
																						onClick={() =>
																							this.handleAdd({
																								type: 'owed',
																								maxItems: 10,
																								errorMessage: t('cant_exceed', {
																									number: 10,
																									content: t('documents'),
																								}),
																								target: `owedInput_${owed.length}`,
																							})
																						}
																					/>
																				</div>
																			)}
																			<div className='center'>
																				<CustomButton
																					endIcon={<KeyboardArrowDownIcon />}
																					btnText={t('next')}
																					margin='1rem auto 0'
																					onClick={() =>
																						this.fillNextTopic(
																							'lifePreferences',
																						)
																					}
																				/>
																			</div>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<h2 className='mg textTitle flex'>
																	<AllInclusiveIcon className='mgrH bottomTranslate8' />{' '}
																	{t('post_mortem')}
																</h2>

																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'lifePreferences'}
																	onChange={() =>
																		this.changePanel('lifePreferences')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='lifePreferences'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<EditNoteIcon id='iconAccordion' />{' '}
																			{t('end_of_life')}{' '}
																			{matches.isMobile &&
																				t('end_of_life_desc_1')}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('end_of_life_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>

																	<AccordionDetails>
																		<p className='mgbOnly'>
																			{t('specific_wishes')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>
																							{t('end_of_life_example_desc_1')}
																						</li>
																						<li>
																							{t('end_of_life_example_desc_2')}
																						</li>
																						<li>
																							{t('end_of_life_example_desc_3')}
																						</li>
																					</ul>
																				}
																			/>
																		</p>

																		<CustomInput
																			variant='outlined'
																			placeholder={t('type_here')}
																			name='lifePreferences'
																			inputValue={lifePreferences}
																			inputId='lifePreferences'
																			onChange={(e) => this.handleChange(e)}
																			fullWidth
																			maxLength={5000}
																			helperText={
																				lifePreferences.length > 500
																					? `${lifePreferences.length}/5000`
																					: ''
																			}
																			minRows={3}
																			multiline
																		/>
																		<p className='flex'>
																			<PlagiarismSharpIcon className='mgrH ' />
																			{t('funeral_arrangements')}
																		</p>

																		<p className='mgbOnly'>
																			{t('funeral_arrangements_desc_1')}
																			<CustomPopover
																				dialog
																				tips
																				tooltip={t('open')}
																				title={t('examples')}
																				className='topTranslate3'
																				content={
																					<ul className='text pd mgX'>
																						<li>
																							{t(
																								'funeral_arrangements_example_1',
																							)}
																						</li>
																						<li>
																							{t(
																								'funeral_arrangements_example_2',
																							)}
																						</li>
																						<li>
																							{t(
																								'funeral_arrangements_example_3',
																							)}
																						</li>
																						<li>
																							{t(
																								'funeral_arrangements_example_4',
																							)}
																						</li>
																						<li>
																							{t(
																								'funeral_arrangements_example_5',
																							)}
																						</li>
																					</ul>
																				}
																			/>
																		</p>

																		<CustomInput
																			variant='outlined'
																			placeholder={t('type_here')}
																			name='funeralArrangements'
																			inputId='funeralArrangements'
																			inputValue={funeralArrangements}
																			onChange={(e) => this.handleChange(e)}
																			fullWidth
																			maxLength={5000}
																			helperText={
																				funeralArrangements.length > 500
																					? `${funeralArrangements.length}/5000`
																					: ''
																			}
																			minRows={3}
																			multiline
																		/>
																		<div className='center'>
																			<CustomButton
																				endIcon={<KeyboardArrowDownIcon />}
																				btnText={t('next')}
																				margin='1rem auto 0'
																				onClick={() =>
																					this.fillNextTopic('audioRecording')
																				}
																			/>
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Accordion
																	slotProps={{
																		transition: { unmountOnExit: true },
																	}}
																	expanded={expanded === 'audioRecording'}
																	onChange={() =>
																		this.changePanel('audioRecording')
																	}
																>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		aria-controls='goodbyeLetterPanelbh-content'
																		id='audioRecording'
																	>
																		<Typography
																			className='flex'
																			sx={{
																				width: matches.isMobile
																					? '100%'
																					: '33%',
																			}}
																			id='typographyLetter'
																		>
																			<RadioButtonCheckedIcon
																				className='iconRed'
																				id='iconAccordion'
																			/>{' '}
																			{t('record_audio')}
																			{matches.isMobile
																				? `: ${t('record_audio_desc_1')}`
																				: ''}
																		</Typography>
																		{!matches.isMobile && (
																			<p className='mg0'>
																				{t('record_audio_desc_1')}
																			</p>
																		)}
																	</AccordionSummary>
																	<AccordionDetails sx={{ padding: '0' }}>
																		<div className='pdX'>
																			<p className='mgtOnly'>
																				{t('record_audio_desc_2')}
																			</p>
																			<p className='mgtOnly'>
																				{t('record_audio_desc_3')}
																			</p>
																			<p className='mgtOnly'>
																				{t('record_audio_desc_4')}
																			</p>
																		</div>

																		<CustomAlerts
																			error={exceededFileSize}
																			noMargin
																			alertId='smallMuiAlert'
																		/>
																		{fileErrors === t('suported_files') && (
																			<CustomAlerts
																				error={fileErrors}
																				message={t('unsupported_ext')}
																				noMargin
																				alertId='smallMuiAlert'
																			/>
																		)}
																		<CustomAlerts
																			info={fileErrors}
																			severity='warning'
																			close
																			margin='0 0 1rem 0'
																			onClose={this.clearFileErrors}
																			alertId='smallMuiAlert'
																		/>
																		{!matches.isMobile &&
																			!editedAudioFile &&
																			!stateFileToUpload &&
																			fileType === '' && (
																				<div>
																					<input
																						type='file'
																						accept='audio/*'
																						id='audioUploadInput'
																						hidden='hidden'
																						onChange={
																							this.handleFileChangeAndSubmit
																						}
																						name='audio'
																					/>
																					<div className='pd'>
																						<FileDrop
																							onDragOver={() =>
																								this.setDragOver(true)
																							}
																							onDragLeave={() =>
																								this.setDragOver(false)
																							}
																							onDrop={(files, event) =>
																								this.handleFileChangeAndSubmit(
																									event,
																									false,
																									files,
																								)
																							}
																						>
																							<div
																								onClick={(e) =>
																									this.handleOpenFile(
																										e,
																										'audioUploadInput',
																									)
																								}
																								className={`
											pointer  ${fileDragOver ? 'dropArea_active' : 'dropArea_inactive'}
										`}
																							>
																								<span className='file-msg'>
																									{matches.isMobile
																										? t('add_audio_file')
																										: fileDragOver
																										? t('release_to_upload')
																										: t('click_or_drag_audio')}
																								</span>
																							</div>
																						</FileDrop>
																					</div>
																				</div>
																			)}
																		<div className='center'>
																			<Grow in={true}>
																				<div>
																					<CustomAlerts
																						noMargin
																						info={
																							!editedAudioFile &&
																							(fileName || deletedFileName) &&
																							// !deletedAudioFile &&
																							(stateFileToUpload ||
																								setUndoDeletedFile)
																						}
																						severity={
																							setUndoDeletedFile
																								? 'warning'
																								: 'success'
																						}
																						icon={<GraphicEqIcon />}
																						message={
																							!setUndoDeletedFile
																								? fileName
																								: deletedFileName
																						}
																						tooltip={
																							setUndoDeletedFile
																								? t('undo')
																								: t('delete')
																						}
																						closeIcon={
																							!setUndoDeletedFile ? (
																								<DeleteOutlineIcon />
																							) : setUndoDeletedFile ? (
																								<ReplayIcon />
																							) : null
																						}
																						onClose={
																							!setUndoDeletedFile
																								? this.handleDeleteFile
																								: setUndoDeletedFile
																								? this.undoDeletedFile
																								: null
																						}
																						close={true}
																						secondTooltip={t('delete')}
																						secondClose={setUndoDeletedFile}
																						secondIcon={<DeleteOutlineIcon />}
																						secondAction={() =>
																							this.clearDeletedFile('audio')
																						}
																					/>
																				</div>
																			</Grow>
																			{stateFileToUpload ? (
																				<AudioPlayer
																					fileUrl={stateFileToUpload}
																					postId='preview'
																					waveColor='#7e7e7e'
																					isMobile={matches.isMobile}
																					makePostFunctionality
																				/>
																			) : (
																				<div
																					className={
																						matches.isMobile ? 'mgt' : ''
																					}
																				>
																					<AudioRecorder
																						editedAudioFile={editedAudioFile}
																						deletedAudioFile={deletedAudioFile}
																						deletedEditedAudioFileToUpload={
																							deletedEditedAudioFileToUpload
																						}
																						severity={
																							setUndoDeletedFile
																								? 'warning'
																								: 'success'
																						}
																						setFileType={this.setFileType}
																						icon={<GraphicEqIcon />}
																						message={t('audio')}
																						tooltip={
																							setUndoDeletedFile
																								? t('undo')
																								: t('delete')
																						}
																						closeIcon={
																							!setUndoDeletedFile ? (
																								<DeleteOutlineIcon />
																							) : setUndoDeletedFile ? (
																								<ReplayIcon />
																							) : null
																						}
																						secondTooltip={t('delete')}
																						secondClose={setUndoDeletedFile}
																						secondIcon={<DeleteOutlineIcon />}
																						secondAction={() =>
																							this.clearDeletedFile('audio')
																						}
																						onClose={
																							!setUndoDeletedFile
																								? this.handleDeleteFile
																								: setUndoDeletedFile
																								? this.undoDeletedFile
																								: null
																						}
																						openAudioInput={!editedAudioFile}
																						clickAudioInput={(e) =>
																							this.handleOpenFile(
																								e,
																								'audioUploadInput',
																							)
																						}
																					/>
																				</div>
																			)}
																		</div>
																	</AccordionDetails>
																</Accordion>
																<Divider textAlign='left' id='mgY'>
																	{t('choose_custom_topics')}
																</Divider>

																<div className='pdX'>
																	<Autocomplete
																		// onOpen={() => this.handleAutocomplete}
																		// onClose={() => this.handleAutocomplete}
																		onChange={(e, newValue) =>
																			newValue &&
																			this.handleAdd({
																				type: 'customTopics',
																				maxItems: 10,
																				errorMessage: t('cant_exceed', {
																					number: 10,
																					content: t('custom_topics'),
																				}),
																				target: `customTopicsInput_${customTopics.length}`,

																				newValue: newValue.topic,
																				eValue: e.target.value,
																			})
																		}
																		autoHighlight={true}
																		forcePopupIcon
																		filterOptions={(options, params) => {
																			const filtered = filter(options, params);

																			if (params.inputValue !== '') {
																				filtered.push({
																					inputValue: params.inputValue,
																					topic: params.inputValue,
																				});
																			}

																			return filtered;
																		}}
																		id='Topic'
																		options={[
																			{
																				id: Math.random().toString(),
																				topic: t('looking_to_the_future'),
																				title: t('looking_to_the_future_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('words_of_encouragement'),
																				title: t('words_of_encouragement_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('funny_anecdotes'),
																				title: t('funny_anecdotes_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('memorable_moments'),
																				title: t('memorable_moments_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('lesson_learned'),
																				title: t('lesson_learned_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('expressing_regrets'),
																				title: t('expressing_regrets_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('impact_on_you'),
																				title: t('impact_on_you_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('gratitude'),
																				title: t('gratitude_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('favorite_projects'),
																				title: t('favorite_projects_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('wishing_success'),
																				title: t('wishing_success_desc'),
																			},
																			{
																				id: Math.random().toString(),
																				topic: t('closing_note'),
																				title: t('closing_note_desc'),
																			},
																		]}
																		getOptionLabel={(option) => {
																			// e.g. value selected with enter, right from the input
																			if (typeof option === 'string') {
																				return option;
																			}
																			if (option.inputValue) {
																				return option.inputValue;
																			}
																			return option.topic;
																		}}
																		renderOption={(props, option) => (
																			<li
																				{...props}
																				key={
																					option.id ? option.id : Math.random()
																				}
																			>
																				<div className='flex'>
																					{option.title ? (
																						<span>
																							<u className='bold'>
																								{option.topic}
																							</u>
																							: {option.title}
																						</span>
																					) : (
																						<span>
																							{t('add')} {option.topic}
																						</span>
																					)}
																				</div>
																			</li>
																		)}
																		fullWidth
																		freeSolo
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label={t('add_a_topic')}
																				fullWidth
																				inputProps={{
																					...params.inputProps,
																					maxLength: 100,
																				}}
																			/>
																		)}
																	/>
																</div>
																<ReactSortable
																	handle='.swapDrag'
																	list={customTopics}
																	disabled={customTopics.length < 2}
																	setList={(newState) =>
																		this.setState({ customTopics: newState })
																	}
																	swap
																	animation='200'
																	easing='ease-out'
																	dragClass='sortable-drag'
																	ghostClass='ghost'
																	forceFallback={true}
																	onStart={() =>
																		document.body.classList.add('grabbing')
																	}
																	onEnd={() => {
																		document.body.classList.remove('grabbing');
																	}}
																>
																	{customTopics.map((item, index) => (
																		<div
																			key={item.id}
																			className='grayBorders mg'
																			tabIndex={0}
																			id={item.id}
																		>
																			<ListItem disablePadding>
																				<div className='gbChecklist pd'>
																					{customTopics.length > 1 && (
																						<ListItemIcon
																							className='swapDrag'
																							sx={{
																								minWidth: '40px',
																							}}
																						>
																							<SwapVertSharpIcon id='swapReorderIcon' />
																						</ListItemIcon>
																					)}

																					<CustomInput
																						variant='standard'
																						placeholder={t('type_here')}
																						label={t('topic_title')}
																						inputValue={item.topic}
																						onChange={(e) =>
																							this.handleInputChange(
																								e,
																								index,
																								'topic',
																								'customTopics',
																							)
																						}
																						fullWidth
																						maxLength={100}
																						helperText={
																							item.topic.length >= 50 &&
																							`${item.topic.length}/100`
																						}
																						multiline
																						margin='0'
																					/>
																					<CustomButton
																						iconButton
																						onClick={() =>
																							this.handleRemove(
																								index,
																								'customTopics',
																							)
																						}
																						margin='0 -0.5rem 0 0.5rem'
																						tooltip={t('delete')}
																						className='sortable-drag'
																						placement='top'
																						startIcon={
																							<DeleteOutlineSharpIcon id='red' />
																						}
																					/>
																				</div>
																			</ListItem>
																			<hr className='mg0' />

																			<div className='pd'>
																				<CustomInput
																					placeholder={t('type_here')}
																					variant='outlined'
																					multiline
																					fullWidth
																					maxLength={3000}
																					minRows={3}
																					// inputId={`${index}`}
																					inputId={`customTopicsInput_${index}`}
																					helperText={`${item.content.length}/3000`}
																					inputValue={item.content}
																					margin='0'
																					onChange={(e) =>
																						this.handleInputChange(
																							e,
																							index,
																							'content',
																							'customTopics',
																						)
																					}
																				/>
																			</div>
																		</div>
																	))}
																</ReactSortable>
																<div className='flex'>
																	<CustomButton
																		btnText={t('next')}
																		onClick={() => this.openPanel('sendTo')}
																		variant='contained'
																		id='customCancelButton'
																		margin='2rem auto'
																		startIcon={<HistoryEduIcon />}
																	/>
																</div>
															</div>
														) : panel === 'sendTo' ? (
															<div>
																{Object.keys(goodbyeLetter).length > 0 &&
																	(goodbyeLetter.sent ||
																		letters.findIndex(
																			(obj) =>
																				obj.letterId === goodbyeLetter.letterId,
																		) !== -1) && (
																		<div>
																			<CustomAlerts
																				margin='1rem 0 0.5rem 0'
																				icon={<HistoryEduIcon />}
																				message={t('add_more_email_prompt')}
																				info={true}
																				alertId='smallMuiAlert'
																			/>
																			<CustomAlerts
																				success={true}
																				margin='0.5rem 0'
																				message={t(
																					'letter_sent_email_recipients',
																					{
																						date: new Date(
																							goodbyeLetter.releaseDate,
																						),
																					},
																				)}
																				alertId='smallMuiAlert'
																			/>
																			<CustomAlerts
																				margin='0.5rem 0'
																				info={true}
																				severity='warning'
																				message={t(
																					'removing_recipients_prompt',
																				)}
																				alertId='smallMuiAlert'
																			/>
																			<CustomAlerts
																				margin='0.5rem 0 1rem 0'
																				info={true}
																				icon={<EmailIcon />}
																				severity='secondary'
																				message={t('email_recipients_prompt')}
																				alertId='smallMuiAlert'
																			/>
																			<hr />
																		</div>
																	)}
																<div className='mgtH'>
																	{editLetterId === goodbyeLetter.letterId ? (
																		<p className='center textTitle'>
																			{t('choose_recipients')}
																		</p>
																	) : (
																		<CustomAlerts
																			info={t('choose_recipients')}
																			alertId='smallMuiAlert'
																		/>
																	)}
																</div>

																<div className='flex spaceEvenly mgb center'>
																	<div className='widthHalf'>
																		<FormControlLabel
																			onClick={() =>
																				this.handleChecked(
																					'recipientType',
																					'user',
																				)
																			}
																			control={
																				<Radio
																					checked={recipientType === 'user'}
																				/>
																			}
																			labelPlacement='bottom'
																			label={
																				<p className='mgXH bold notranslate'>
																					Goodbye App
																				</p>
																			}
																		/>
																	</div>
																	<div className='widthHalf'>
																		<FormControlLabel
																			onClick={() =>
																				this.handleChecked(
																					'recipientType',
																					'email',
																				)
																			}
																			control={
																				<Radio
																					checked={recipientType === 'email'}
																				/>
																			}
																			labelPlacement='bottom'
																			label={
																				<p className='mgXH bold'>
																					{t('email_address')}
																				</p>
																			}
																		/>
																	</div>
																</div>

																{errorLoadingLetterRecipientsData ? (
																	<CustomAlerts
																		error={errorLoadingLetterRecipientsData}
																		message={t('unable_to_load_rec')}
																		alertId='smallMuiAlert'
																	/>
																) : loadingLetterRecipientsData ? (
																	<div>
																		<Recipients
																			avatars={true}
																			loadRecipients={[1]}
																			message={t('loading')}
																		/>
																		<h5 className='mp_title mgtOnly'>
																			{t('loading')}
																		</h5>
																	</div>
																) : letterRecipients.length > 0 ? (
																	<div className='mgX'>
																		<Recipients
																			recipients={letterRecipients}
																			avatars={true}
																		/>

																		<p className='center mgt'>
																			{t('spam_email_warning')}
																		</p>
																		<Recipients
																			recipients={letterRecipients}
																			deleteRecipient={this.delRecipient}
																		/>
																	</div>
																) : null}

																{emailRecipients.length > 0 && (
																	<div>
																		<p className='mgX capitalize'>
																			{t('email_recipients')}:
																		</p>
																		<Paper
																			elevation={3}
																			sx={{
																				display: 'flex',
																				justifyContent: 'center',
																				flexDirection: 'row',
																				flexWrap: 'wrap',
																				listStyle: 'none',
																				p: 0.5,
																				m: 0,
																			}}
																			component='ul'
																		>
																			{emailRecipients.map((email, index) => {
																				return (
																					<ListItem
																						key={index}
																						sx={{ justifyContent: 'center' }}
																					>
																						<Chip
																							avatar={
																								<Avatar className='bold notranslate'>
																									{email
																										.charAt(0)
																										.toUpperCase()}
																								</Avatar>
																							}
																							variant='outlined'
																							color={
																								mode === 'dark'
																									? 'default'
																									: 'info'
																							}
																							label={
																								<p className='mg0 bold breakWord'>
																									{email}
																								</p>
																							}
																							onDelete={() =>
																								this.delRecipient({
																									email: email,
																								})
																							}
																						/>
																					</ListItem>
																				);
																			})}
																		</Paper>
																	</div>
																)}

																{goodbyeLetter.sentTo >= 20 ? (
																	<CustomAlerts
																		info={
																			goodbyeLetter.sentTo >= 20 &&
																			recipientType === 'user'
																		}
																		severity='warning'
																		message={t('content_spam', {
																			content: t('letter').toLowerCase(),
																			action: t('recipients'),
																		})}
																		icon={<AccountCircleIcon />}
																		margin='1rem 0 0'
																		alertId='smallMuiAlert'
																	/>
																) : recipientType === 'user' ? (
																	<div>
																		<div className='pdX'>
																			<p className='mg0'>
																				{t('choose_recipients')}
																			</p>
																			<RadioGroup
																				name='radio-buttons-group'
																				id='meridiem_mp'
																				value={queryField}
																				onChange={this.radioChange}
																			>
																				<FormControlLabel
																					value='Users'
																					control={<Radio />}
																					label={t('all_users')}
																				/>
																				<FormControlLabel
																					value='Followers'
																					control={<Radio />}
																					label={t('users_that_follow_you', {
																						number: followerCount,
																					})}
																				/>
																				<FormControlLabel
																					value='Following'
																					control={<Radio />}
																					label={t('users_you_follow', {
																						number: followingCount,
																					})}
																				/>
																			</RadioGroup>
																		</div>
																		{showFollowers && (
																			<FollowerCount
																				makePostFunctionality
																				goodbyeLetter
																			/>
																		)}
																		{showFollowing && (
																			<FollowingCount
																				makePostFunctionality
																				goodbyeLetter
																			/>
																		)}
																		{showUsers && (
																			<Searchbar
																				makePostFunctionality
																				goodbyeLetter
																			/>
																		)}
																	</div>
																) : null}
																{goodbyeLetter.sentToEmails >= 20 ? (
																	<CustomAlerts
																		info={
																			goodbyeLetter.sentToEmails >= 20 &&
																			recipientType === 'email'
																		}
																		severity='warning'
																		message={t('content_spam', {
																			content: t('letter').toLowerCase(),
																			action: t('email_recipients'),
																		})}
																		icon={<EmailIcon />}
																		margin='1rem 0 0'
																		alertId='smallMuiAlert'
																	/>
																) : recipientType === 'email' ? (
																	<div className='pdbExcept center'>
																		<CustomInput
																			variant='outlined'
																			type='email'
																			titleLabel={t('email_address')}
																			inputId='recipientEmail'
																			placeholder={t('email')}
																			name='recipientEmail'
																			autoComplete='email'
																			onChange={this.handleChangeEmailRecipient}
																			helperText={emailErrors.email}
																			inputError={
																				emailErrors.email ? true : false
																			}
																			inputValue={recipientEmail}
																			inputAdornmentPosition='end'
																			maxLength={320}
																			fullWidth={true}
																			isEmail={isEmail(recipientEmail)}
																		/>

																		<CustomButton
																			btnText={t('add_email')}
																			startIcon={<AddCircleOutlineSharpIcon />}
																			margin='1rem 0 0'
																			disabled={recipientEmail === ''}
																			variant={
																				isEmail(recipientEmail)
																					? 'outlined'
																					: 'text'
																			}
																			color={
																				isEmail(recipientEmail)
																					? 'primary'
																					: 'inherit'
																			}
																			onClick={this.addRecipientEmail}
																		/>
																	</div>
																) : null}

																<div className='flex'>
																	<CustomButton
																		btnText={t('next')}
																		onClick={() =>
																			this.openPanel(
																				(editLetterId ===
																					goodbyeLetter.letterId &&
																					goodbyeLetter.sent) ||
																					(letters &&
																						letters.findIndex(
																							(obj) =>
																								obj.letterId === editLetterId,
																						) !== -1)
																					? 'preview'
																					: 'sendOn',
																			)
																		}
																		variant='contained'
																		id='customCancelButton'
																		margin='2rem auto'
																		startIcon={<HistoryEduIcon />}
																	/>
																</div>
															</div>
														) : panel === 'sendOn' ? (
															<div>
																<form
																	encType='multipart/form-data'
																	id='textForm'
																	style={{ overflow: 'hidden' }}
																	ref={this.containerRefStep3}
																	className='mgtH'
																>
																	<CustomAlerts
																		info={
																			releaseNow
																				? t('send_now')
																				: isSameDay(
																						dateValue,
																						new Date().getTime(),
																				  )
																				? t('send_today')
																				: t('select_release_date')
																		}
																	/>

																	{releaseNow ? (
																		<p className='mg0 pdX center '>
																			<EventAvailableRoundedIcon
																				id='mp_dateIcon'
																				color='primary'
																			/>

																			<span>
																				{dayjs(Date.now()).format('LLLL')}
																			</span>
																		</p>
																	) : (
																		<p className='mg0 pdX center '>
																			<TodayRoundedIcon
																				onClick={this.openDateTimePicker}
																				id='mp_dateIconClicker'
																				color='primary'
																			/>
																			{dateValue !== null && (
																				<span>
																					{dayjs(dateValue).format(
																						'dddd, MMMM D, YYYY',
																					)}
																				</span>
																			)}
																		</p>
																	)}

																	<div className='flex spaceAround center mgb'>
																		<div>
																			<FormControlLabel
																				control={
																					<Radio
																						checked={releaseNow}
																						onClick={() =>
																							!releaseNow &&
																							this.handleChecked(
																								'releaseNow',
																								true,
																							)
																						}
																					/>
																				}
																				label={
																					<p className='mg0 bold'>{t('now')}</p>
																				}
																				labelPlacement='bottom'
																			/>
																		</div>
																		<div>
																			<FormControlLabel
																				control={
																					<Radio
																						checked={!releaseNow}
																						onClick={() =>
																							releaseNow &&
																							this.handleChecked(
																								'releaseNow',
																								false,
																							)
																						}
																					/>
																				}
																				label={
																					<p className='mg0 bold'>
																						{t('future')}
																					</p>
																				}
																				labelPlacement='bottom'
																			/>
																		</div>
																	</div>

																	{!releaseNow && (
																		<div>
																			<Divider>{t('up_to_30_years')}</Divider>
																			<Slide
																				in={true}
																				direction='up'
																				container={
																					this.containerRefStep3.current
																				}
																			>
																				<div>
																					<DateAndTimePicker
																						makePostFunctionality
																					/>
																					<p className='center mgtOnly'>
																						{t('notified_on_release', {
																							content:
																								t('letter').toLowerCase(),
																						})}
																					</p>
																				</div>
																			</Slide>
																		</div>
																	)}
																</form>
																<div className='flex'>
																	<CustomButton
																		btnText={t('next')}
																		onClick={() => this.setPreview('preview')}
																		variant='contained'
																		id='customCancelButton'
																		margin='2rem auto'
																		startIcon={<HistoryEduIcon />}
																	/>
																</div>
															</div>
														) : panel === 'preview' ? (
															<div className='pdX'>
																{!loadingGoodbyeLetter &&
																	(letterOrder.length > 1 ||
																		(letterOrder.length === 1 &&
																			letterOrder[0].key !== 'title')) &&
																	(Object.keys(goodbyeLetter).length === 0 ||
																		(Object.keys(goodbyeLetter).length > 0 &&
																			goodbyeLetter.sent === false) ||
																		unreleasedLetters.findIndex(
																			(obj) =>
																				obj.letterId === goodbyeLetter.letterId,
																		) !== -1) && (
																		<div className='pdb'>
																			<p className='mgY textTitle flex leftTranslate2 alignItemsCenter'>
																				<FormatListNumberedIcon className='mgrH ' />{' '}
																				{t('drag_to_reorder')}
																			</p>

																			<ReactSortable
																				list={letterOrder}
																				disabled={letterOrder.length < 2}
																				setList={(newState) =>
																					this.setState({
																						letterOrder: newState,
																					})
																				}
																				swap
																				animation='200'
																				easing='ease-out'
																				pressDelay
																				handle={
																					matches.isMobile ? '.swapDrag' : ''
																				}
																				dragClass='sortable-drag'
																				ghostClass='ghost'
																				forceFallback={true}
																				onStart={() =>
																					document.body.classList.add(
																						'grabbing',
																					)
																				}
																				onEnd={() => {
																					document.body.classList.remove(
																						'grabbing',
																					);
																				}}
																			>
																				{letterOrder.map((topic, index) =>
																					topic.key === 'title' ? (
																						[]
																					) : (
																						<ListItem
																							disablePadding
																							className='grayBorders'
																							key={`${topic.key}`}
																						>
																							<div className='gbChecklist pd dragPreview'>
																								<div className='flex'>
																									{letterOrder.length > 1 && (
																										<ListItemIcon
																											className='swapDrag'
																											sx={{
																												minWidth: '35px',
																											}}
																										>
																											<SwapVertSharpIcon id='swapReorderIcon' />
																										</ListItemIcon>
																									)}
																									{index + 1}.{' '}
																									<b className='mgl4px'>
																										{/* {titleCase(topic.key)} */}
																										{t(transformKey(topic.key))}
																									</b>
																								</div>
																								{topic.key ===
																									'customTopics' && (
																									<small className='mgtH'>
																										{customTopics.map(
																											(obj, index) => {
																												return `${titleCase(
																													obj.topic,
																													true,
																												)}${
																													index + 1 !==
																													customTopics.length
																														? ', '
																														: ''
																												}`;
																											},
																										)}
																									</small>
																								)}
																							</div>
																						</ListItem>
																					),
																				)}
																			</ReactSortable>
																		</div>
																	)}
																{Object.keys(goodbyeLetter).length > 0 && (
																	<div>
																		<CustomAlerts
																			success={
																				goodbyeLetter.sent ||
																				letters.findIndex(
																					(obj) =>
																						obj.letterId ===
																						goodbyeLetter.letterId,
																				) !== -1
																			}
																			message={t('letter_sent_from_now', {
																				date: dayjs(
																					goodbyeLetter.releaseDate,
																				).fromNow(),
																			})}
																			margin='1rem 0 0'
																			alertId='smallMuiAlert'
																		/>
																		<CustomAlerts
																			info={goodbyeLetter.prevReleaseDate}
																			message={t('release_date_inactivity', {
																				date: dayjs(
																					goodbyeLetter.prevReleaseDate,
																				).format('LLLL'),
																			})}
																			icon={<TimerIcon />}
																			margin='1rem 0 0'
																			severity='warning'
																			alertId='smallMuiAlert'
																		/>
																	</div>
																)}

																<Divider>
																	<p>{t('letter_preview')}</p>
																</Divider>
																<p className='mgbOnly'>
																	<span className='bold'>
																		{t('email_subject')}
																	</span>
																	:{' '}
																	<span className='capitalize notranslate'>
																		{name ? name : handle}{' '}
																	</span>
																	{t('wanted_you_to_receive')}
																</p>
																<div className='center'>
																	<div className='cardEmailSender notranslate'>
																		{imageUrl === noImgUrl() ? (
																			<div
																				id='imgEmailCard'
																				style={{ overflow: 'hidden' }}
																			>
																				<img
																					src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/emailBlankProfile.png?alt=media&token=604541ba-7ffd-4511-98a6-72fe61ee6f03'
																					alt={`${handle} img`}
																					id='noImgTransparent'
																				/>
																			</div>
																		) : (
																			<img
																				src={imageUrl}
																				alt={`${handle} img`}
																				id='imgEmailCard'
																			/>
																		)}
																		<p
																			className='white mg0 imgHandleCard capitalize notranslate'
																			style={{
																				textShadow: '2px 1px #000',
																			}}
																		>
																			{name ? name : handle}
																		</p>
																	</div>
																	<img
																		src={gbLetterBackground}
																		alt='lake background'
																		id='mailCard'
																	/>
																</div>

																<div
																	style={dim(loadingGoodbyeLetter)}
																	className='mgtOnly'
																>
																	{!goodbyeLetter.sent ||
																	unreleasedLetters.findIndex(
																		(obj) =>
																			obj.letterId === goodbyeLetter.letterId,
																	) !== -1 ? (
																		<CustomInput
																			variant='filled'
																			placeholder={t('title')}
																			name='title'
																			inputId='title'
																			inputValue={title}
																			onChange={(e) => this.handleChange(e)}
																			fullWidth
																			maxLength={100}
																			helperText={`${title.length}/100`}
																			multiline
																			maxRows={1}
																			title
																			margin='0 0 1rem 0'
																		/>
																	) : title ? (
																		<h2 className='underline center'>
																			{title}
																		</h2>
																	) : null}

																	<LetterTemplate
																		sent={
																			goodbyeLetter.sent ||
																			letters.findIndex(
																				(obj) =>
																					obj.letterId ===
																					goodbyeLetter.letterId,
																			) !== -1
																		}
																		recording={
																			editedAudioFile
																				? editedAudioFile
																				: stateFileToUpload
																		}
																		loading={loadingGoodbyeLetter}
																		state={this.state}
																		edit={this.edit}
																		openPanel={this.openPanel}
																		handleRemoveTopic={this.handleRemoveTopic}
																		handleRemoveCustomTopic={
																			this.handleRemoveCustomTopic
																		}
																		getInitialValuesForType={
																			this.getInitialValuesForType
																		}
																		titleCase={titleCase}
																		transformKey={transformKey}
																		isMobile={matches.isMobile}
																	/>
																</div>

																<FooterLetterLogo mode={mode} />
																<Divider />
																{dateValue && (
																	<CustomAlerts
																		success={true}
																		message={
																			<TimerCountdown
																				previewLetter
																				text={t('sends_in')}
																				releaseDate={dateValue}
																				selectorId={'timer'}
																			/>
																		}
																		icon={<BrowseGalleryIcon />}
																		noMargin
																		alertId='smallMuiAlert'
																	/>
																)}
																<CustomAlerts
																	error={errorUpdatingGoodbyeLetter}
																	close
																	alertId='smallMuiAlert'
																/>
																<CustomAlerts
																	error={errorUploadingLetterAudio}
																	close
																	alertId='smallMuiAlert'
																/>
																<Progress
																	progress={uploadingLetterAudioProgress}
																	title={t('uploading_audio')}
																	icon={<GraphicEqIcon />}
																	variant={
																		uploadingLetterAudioProgress
																			? 'buffer'
																			: 'determinate'
																	}
																/>

																<div className='flex center spaceAround'>
																	{(goodbyeLetter.sent ||
																		letters.findIndex(
																			(obj) =>
																				obj.letterId === goodbyeLetter.letterId,
																		) !== -1) && (
																		<CustomButton
																			btnText={t('close')}
																			onClick={() => this.close('onlyLetter')}
																			variant='contained'
																			id='customCancelButton'
																			margin='1rem 0'
																			startIcon={<HistoryEduIcon />}
																		/>
																	)}
																	{(!goodbyeLetter.letterId ||
																		updateLetter) && (
																		<CustomButton
																			loading={
																				uploadingLetterAudioProgress ||
																				updatingGoodbyeLetter ||
																				loadingGoodbyeLetter ||
																				deletingGoodbyeLetter
																			}
																			btnText={
																				goodbyeLetter.letterId
																					? t('update')
																					: dateValue
																					? t('save')
																					: t('send')
																			}
																			startIcon={
																				dateValue ? (
																					<CheckCircleOutlineOutlinedIcon />
																				) : (
																					<SendIcon />
																				)
																			}
																			id={'customInfoButton'}
																			onClick={this.save}
																			margin='1rem 0'
																		/>
																	)}
																</div>
															</div>
														) : panel === 'letters' ? (
															<div className='pdX'>
																<p className='textTitle flex leftTranslate2 alignItemsCenter'>
																	<ModeEditIcon
																		className='mgrH'
																		fontSize='small'
																	/>{' '}
																	{t('your_letters')}
																</p>
																<div
																	className={`${
																		matches.isMobile ? '' : 'flex'
																	} ${matches.verySmall ? 'pdt' : ''}`}
																>
																	<Tabs
																		orientation={
																			matches.isMobile
																				? 'horizontal'
																				: 'vertical'
																		}
																		value={letterCollection}
																		onChange={this.handlePanelChange}
																		centered
																		variant='fullWidth'
																		sx={
																			matches.isMobile
																				? {
																						margin: '-2rem 0 1.5rem',
																				  }
																				: {
																						borderRight: 1,
																						borderColor: 'divider',
																						marginRight: '1rem',
																				  }
																		}
																	>
																		<Tab
																			id='tabs'
																			icon={<MarkEmailReadIcon />}
																			iconPosition='start'
																			label={
																				matches.verySmall
																					? ''
																					: t('sent_plural')
																			}
																			value='sentLetters'
																			sx={{
																				marginRight: '1rem',
																			}}
																		/>
																		<Tab
																			id='tabs'
																			icon={<TodayIcon />}
																			iconPosition='start'
																			label={
																				matches.verySmall ? '' : t('scheduled')
																			}
																			value='unreleasedLetters'
																			sx={{ marginRight: '1rem' }}
																		/>
																		{!matches.isMobile && (
																			<Tab
																				id='tabs'
																				icon={<AddCircleOutlineSharpIcon />}
																				iconPosition='start'
																				label={t('new')}
																				value='newLetter'
																				sx={{ marginRight: '1rem' }}
																			/>
																		)}
																	</Tabs>

																	<div className='flexColumn fullWidth'>
																		{letterCollection === 'sentLetters' &&
																			letters &&
																			letters.length === 0 && (
																				<CustomAlerts
																					info={t('sent_letters')}
																					margin='0'
																					icon={<HistoryEduIcon />}
																					alertId='smallMuiAlert'
																				/>
																			)}
																		{letterCollection === 'unreleasedLetters' &&
																			unreleasedLetters &&
																			unreleasedLetters.length === 0 && (
																				<CustomAlerts
																					info={t('scheduled_letters')}
																					margin='0'
																					icon={<HistoryEduIcon />}
																					alertId='smallMuiAlert'
																				/>
																			)}

																		{letterCollection === 'sentLetters' &&
																			letters &&
																			letters.map((obj, index) => {
																				return (
																					<div key={index} className='pointer'>
																						<div
																							className={`gbl_sentPaperRecipient ${
																								animateLetterId === obj.letterId
																									? 'additional'
																									: ''
																							}`}
																						>
																							<div
																								className='flex spaceBetween alignCenter'
																								onClick={() =>
																									this.handleChangeLetter(
																										obj.letterId,
																									)
																								}
																							>
																								<div className='mp_containerAvatarHandle'>
																									<div className='mp_paperUserRecipientHandle'>
																										<h5>
																											{t('letter')}{' '}
																											{letters.length - index}
																											<span className='time mgl6px'>
																												{t(
																													'sent',
																												).toLowerCase()}{' '}
																												{dayjs(
																													obj.releaseDate,
																												).fromNow()}
																												.
																											</span>
																										</h5>
																									</div>
																								</div>

																								<CustomButton
																									startIcon={
																										<ExpandMoreIcon
																											className={
																												editLetterId ===
																												obj.letterId
																													? 'rotateIcon'
																													: ''
																											}
																										/>
																									}
																									onClick={() =>
																										this.handleChangeLetter(
																											obj.letterId,
																										)
																									}
																									iconButton
																									color='inherit'
																								/>
																							</div>

																							{editLetterId ===
																								obj.letterId && (
																								<div>
																									<hr className='mg0' />
																									<Grow in={true}>
																										<div className='flex spaceAround'>
																											<ConfirmationDialog
																												open={
																													letterConfirmationDialog
																												}
																												openFunc={() =>
																													this.loadLetter(
																														obj.letterId,
																														true,
																													)
																												}
																												closeFunc={() =>
																													this.loadLetter(
																														obj.letterId,
																														false,
																													)
																												}
																												variant='text'
																												openIconOrString={t(
																													'read',
																												)}
																												actionBtnText={t(
																													'open_letter',
																													{
																														number: index + 1,
																													},
																												)}
																												customButtonId='customInfoButton'
																												cancelBtnText={t(
																													'continue_editing',
																												)}
																												action={() =>
																													this.loadLetter(
																														obj.letterId,
																														false,
																														true,
																													)
																												}
																												startIcon={
																													<HistoryEduIcon />
																												}
																												phrase={t(
																													'unsaved_changes',
																												)}
																												phrase2={t(
																													'save_letter_warning',
																												)}
																												list={
																													<div id='mgl'>
																														<p>
																															{t(
																																'edited_fields',
																															)}
																														</p>
																														<ul className='bold'>
																															{changedTitles.map(
																																(
																																	topic,
																																	index,
																																) => {
																																	return (
																																		<li
																																			key={
																																				index
																																			}
																																		>
																																			{topic}
																																		</li>
																																	);
																																},
																															)}
																														</ul>
																													</div>
																												}
																											/>
																											<ConfirmationDialog
																												open={
																													openedDeleteLetterId ===
																													obj.letterId
																												}
																												openIconOrString={
																													<DeleteOutlineSharpIcon />
																												}
																												customButtonId='customDangerButton'
																												openFunc={() =>
																													this.handleDeleteLetter(
																														obj.letterId,
																													)
																												}
																												closeFunc={() =>
																													this.handleDeleteLetter(
																														'',
																													)
																												}
																												actionBtnText={t(
																													'delete',
																												)}
																												variant='text'
																												action={() =>
																													this.handleDeleteLetter(
																														obj.letterId,
																														true,
																													)
																												}
																												startIcon={
																													<DeleteOutlineSharpIcon />
																												}
																												phrase={t(
																													'delete_letter',
																													{
																														number: index + 1,
																													},
																												)}
																												component={
																													<div>
																														<CustomAlerts
																															info={true}
																															message={t(
																																'letter_sent_on',
																																{
																																	date: new Date(
																																		goodbyeLetter.releaseDate,
																																	),
																																},
																															)}
																															noMargin
																															icon={
																																<HistoryEduIcon />
																															}
																															alertId='smallMuiAlert'
																														/>
																														<CustomAlerts
																															info={true}
																															severity='warning'
																															message={t(
																																'letter_sent_rec_warning',
																																{
																																	number:
																																		index + 1,
																																},
																															)}
																															noBorder
																															margin='0 0 1rem 0'
																															alertId='smallMuiAlert'
																														/>
																													</div>
																												}
																											/>
																										</div>
																									</Grow>
																								</div>
																							)}
																						</div>
																					</div>
																				);
																			})}
																		{letterCollection === 'unreleasedLetters' &&
																			unreleasedLetters &&
																			unreleasedLetters.map((obj, index) => {
																				return (
																					<div
																						key={index}
																						className='pointer fullWidth'
																					>
																						<div
																							className={`gbl_sentPaperRecipient ${
																								animateLetterId === obj.letterId
																									? 'additional'
																									: ''
																							}`}
																						>
																							<div
																								className='flex spaceBetween alignCenter'
																								onClick={() =>
																									this.handleChangeLetter(
																										obj.letterId,
																									)
																								}
																							>
																								<div className='mp_containerAvatarHandle'>
																									<div className='mp_paperUserRecipientHandle'>
																										<h5 className='flex'>
																											<TimerCountdown
																												releaseDate={
																													obj.releaseDate
																												}
																												letter
																												text={t(
																													'letter_sends_in',
																													{
																														number:
																															unreleasedLetters.length -
																															index,
																													},
																												)}
																												selectorId={`${obj.letterId}_timer`}
																											/>
																										</h5>
																									</div>
																								</div>
																								<div>
																									<CustomButton
																										startIcon={
																											<ExpandMoreIcon
																												className={
																													editLetterId ===
																													obj.letterId
																														? 'rotateIcon'
																														: ''
																												}
																											/>
																										}
																										onClick={() =>
																											this.handleChangeLetter(
																												obj.letterId,
																											)
																										}
																										iconButton
																										color='inherit'
																									/>
																								</div>
																							</div>

																							{editLetterId ===
																								obj.letterId && (
																								<div>
																									<hr className='mg0' />
																									<Grow in={true}>
																										<div className='flex spaceAround'>
																											<ConfirmationDialog
																												open={
																													letterConfirmationDialog
																												}
																												openFunc={() =>
																													this.loadLetter(
																														obj.letterId,
																														true,
																													)
																												}
																												closeFunc={() =>
																													this.loadLetter(
																														obj.letterId,
																														false,
																													)
																												}
																												variant='text'
																												openIconOrString={t(
																													'edit',
																												)}
																												actionBtnText={t(
																													'open_letter',
																													{
																														number: index + 1,
																													},
																												)}
																												customButtonId='customInfoButton'
																												cancelBtnText={t(
																													'continue_editing',
																												)}
																												action={() =>
																													this.loadLetter(
																														obj.letterId,
																														false,
																														true,
																													)
																												}
																												startIcon={
																													<HistoryEduIcon />
																												}
																												phrase={t(
																													'unsaved_changes',
																												)}
																												phrase2={t(
																													'save_letter_warning',
																												)}
																												list={
																													<div id='mgl'>
																														<p>
																															{t(
																																'edited_fields',
																															)}
																														</p>
																														<ul className='bold'>
																															{changedTitles.map(
																																(
																																	topic,
																																	index,
																																) => {
																																	return (
																																		<li
																																			key={
																																				index
																																			}
																																		>
																																			{topic}
																																		</li>
																																	);
																																},
																															)}
																														</ul>
																													</div>
																												}
																											/>
																											<ConfirmationDialog
																												open={
																													openedDeleteLetterId ===
																													obj.letterId
																												}
																												openIconOrString={
																													<DeleteOutlineSharpIcon />
																												}
																												customButtonId='customDangerButton'
																												openFunc={() =>
																													this.handleDeleteLetter(
																														obj.letterId,
																													)
																												}
																												closeFunc={() =>
																													this.handleDeleteLetter(
																														'',
																													)
																												}
																												actionBtnText={t(
																													'delete',
																												)}
																												action={() =>
																													this.handleDeleteLetter(
																														obj.letterId,
																														true,
																													)
																												}
																												variant='text'
																												startIcon={
																													<DeleteOutlineSharpIcon />
																												}
																												phrase={t(
																													'delete_letter',
																													{
																														number: index + 1,
																													},
																												)}
																												contentTitle={t(
																													'cant_be_undone',
																												)}
																											/>
																										</div>
																									</Grow>
																								</div>
																							)}
																						</div>
																					</div>
																				);
																			})}

																		{letterCollection === 'newLetter' && (
																			<div>
																				{letters &&
																				letters.length +
																					unreleasedLetters.length <
																					10 ? (
																					<div className='center'>
																						<CustomAlerts
																							success={t('up_to_10_letters')}
																							alertId='smallMuiAlert'
																							margin='0'
																							icon={<HistoryEduIcon />}
																						/>
																						<CustomButton
																							startIcon={<EditIcon />}
																							btnText={t('write_new_letter')}
																							margin='2rem auto'
																							id='customInfoButton'
																							onClick={() =>
																								this.handleAddLetter(true)
																							}
																						/>
																					</div>
																				) : lettersMadeToday >= 15 ? (
																					<CustomAlerts
																						info={`${t(
																							'you_have_exceeded_daily_limit_action',
																						)} ${t(
																							'security_anti_spam_measures',
																						)}`}
																						alertId='smallMuiAlert'
																						margin='0'
																					/>
																				) : (
																					<CustomAlerts
																						info={t('max_letters')}
																						alertId='smallMuiAlert'
																						margin='0'
																					/>
																				)}
																			</div>
																		)}
																	</div>
																</div>

																{matches.isMobile && (
																	<div>
																		{letters &&
																		letters.length + unreleasedLetters.length <
																			10 ? (
																			<div className='center mgt'>
																				<Divider>
																					<p className='mg0'>
																						{t('up_to_10_letters')}
																					</p>
																				</Divider>
																				<CustomButton
																					startIcon={<EditIcon />}
																					btnText={t('write_new_letter')}
																					margin='1rem auto 1.4rem'
																					id='customInfoButton'
																					onClick={() =>
																						this.handleAddLetter(true)
																					}
																				/>
																			</div>
																		) : lettersMadeToday >= 15 ? (
																			<CustomAlerts
																				info={`${t(
																					'you_have_exceeded_daily_limit_action',
																				)} ${t('security_anti_spam_measures')}`}
																				alertId='smallMuiAlert'
																			/>
																		) : (
																			<CustomAlerts
																				info={t('max_letters')}
																				alertId='smallMuiAlert'
																			/>
																		)}
																	</div>
																)}
																<hr className='lightHr' />
																<p className='textTitle flex leftTranslate2 alignItemsCenter mgtOnly'>
																	{loadingLetterSendersData &&
																	letterSendersData.length > 0 ? (
																		<CircularProgress
																			color='inherit'
																			className='mgrH'
																			size={24}
																			thickness={3}
																		/>
																	) : (
																		<InboxIcon
																			className='mgrH'
																			fontSize='small'
																		/>
																	)}
																	{t('inbox')}
																	{canRecoverLetter &&
																	canRecoverLetter.length === 1
																		? `: 1`
																		: canRecoverLetter &&
																		  canRecoverLetter.length > 1
																		? `: ${canRecoverLetter.length}`
																		: ''}
																</p>

																{canRecoverLetter &&
																	canRecoverLetter.length === 0 && (
																		<CustomAlerts
																			success={t('letters_appear_here')}
																			alertId='smallMuiAlert'
																		/>
																	)}

																{errorLoadingLetterSendersData ? (
																	<CustomAlerts
																		error={errorLoadingLetterSendersData}
																		message={t('unable_to_load_senders')}
																		alertId='smallMuiAlert'
																	/>
																) : loadingLetterSendersData &&
																  letterSendersData.length === 0 ? (
																	<div className='pdb2'>
																		<Recipients
																			avatars={true}
																			loadRecipients={canRecoverLetter}
																			message={t('loading')}
																		/>
																	</div>
																) : letterSendersData.length > 0 &&
																  canRecoverLetter.length > 0 ? (
																	<div className='pdbH'>
																		{letterSendersData.length >= 3 && (
																			<Recipients
																				recipients={letterSendersData}
																				documents={canRecoverLetter}
																				avatars={true}
																				dontShowCount
																			/>
																		)}
																		<div className='mgt'>
																			<Recipients
																				documents={canRecoverLetter}
																				recipients={letterSendersData}
																				radio
																				radioActionIcon={<HistoryEduIcon />}
																				isSmall={matches.semiSmall}
																				radioAction={this.handleReadLetter}
																				loading={loadingLetterSendersData}
																			/>
																		</div>
																	</div>
																) : null}
																{readLetter && (
																	<Dialog
																		open={readLetter}
																		onClose={this.handleCloseLetter}
																		fullWidth
																		maxWidth={!matches.isMobile ? 'xl' : false}
																		fullScreen={matches.isMobile}
																	>
																		<Toolbar id='dialogBar'>
																			<div className='flex alignItemsCenter'>
																				{/* <div className='backgroundLogo mgX topTranslate2'>
																				<img
																					className='logo'
																					src={logo}
																					alt='Logo'
																				/>
																			</div> */}
																				{!matches.isMobile && (
																					<img
																						className='navbarGBLogo'
																						src={gbaLogo}
																						alt='Goodbye App'
																					/>
																				)}
																				<span className='titleToolbar mglH'>
																					{t('letter')}
																				</span>
																			</div>

																			<CloseIcon
																				id='closeDialogButton'
																				onClick={this.handleCloseLetter}
																				aria-label='close'
																			/>
																		</Toolbar>
																		<DialogContent sx={{ padding: 0 }}>
																			{loadingReceivedGoodbyeLetter && (
																				<LinearProgress />
																			)}
																			{errorLoadingReceivedGoodbyeLetter ? (
																				<CustomAlerts
																					error={
																						errorLoadingReceivedGoodbyeLetter
																					}
																					alertId='smallMuiAlert'
																				/>
																			) : (
																				<div>
																					{letterSendersData.map(
																						(obj, index) => {
																							if (
																								receivedGoodbyeLetter.userId ===
																								obj.userId
																							) {
																								return (
																									<div key={index}>
																										<div className='mg flex textTitle alignItemsCenter'>
																											<AccountBoxIcon className='mgrH' />
																											<b className='mgrH capitalize'>
																												{t('user')}:
																											</b>
																											<span>{obj.handle}</span>
																										</div>
																										<p className='mg textTitle'>
																											<EmailIcon className='mgrH bottomTranslate4' />
																											<b className='mgrH'>
																												{t('subject')}
																											</b>

																											<span>
																												<b className='capitalize'>
																													{obj.name
																														? obj.name
																														: obj.handle}
																												</b>{' '}
																												{t(
																													'wanted_you_to_receive',
																												)}
																											</span>
																										</p>

																										<div className='center'>
																											<div className='cardEmailSender'>
																												{obj.imageUrl ===
																												noImgUrl() ? (
																													<div
																														id='imgEmailCard'
																														style={{
																															overflow:
																																'hidden',
																														}}
																													>
																														<img
																															src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/emailBlankProfile.png?alt=media&token=604541ba-7ffd-4511-98a6-72fe61ee6f03'
																															alt={`${obj.handle} img`}
																															id='noImgTransparent'
																														/>
																													</div>
																												) : (
																													<img
																														src={obj.imageUrl}
																														alt={`${obj.handle} img`}
																														id='imgEmailCard'
																													/>
																												)}
																												<p
																													className='white mg0 imgHandleCard capitalize'
																													style={{
																														textShadow:
																															'2px 1px #000',
																													}}
																												>
																													{obj.name
																														? obj.name
																														: obj.handle}
																												</p>
																											</div>

																											<img
																												src={gbLetterBackground}
																												alt='lake background'
																												id='mailCard'
																											/>
																										</div>
																									</div>
																								);
																							} else return null;
																						},
																					)}

																					<div className='mg'>
																						{Object.keys(receivedGoodbyeLetter)
																							.length > 0 ? (
																							<div>
																								{receivedGoodbyeLetter.title !==
																									'' && (
																									<h2 className='underline center'>
																										{
																											receivedGoodbyeLetter.title
																										}
																									</h2>
																								)}
																								<LetterTemplate
																									sent={true}
																									state={receivedGoodbyeLetter}
																									receivedLetter={true}
																									titleCase={titleCase}
																									transformKey={transformKey}
																								/>

																								{receivedGoodbyeLetter.createdAt && (
																									<CustomAlerts
																										success={true}
																										message={t(
																											'released_after_creation',
																											{
																												date:
																													new Date(
																														receivedGoodbyeLetter.releaseDate,
																													).getTime() -
																														new Date(
																															receivedGoodbyeLetter.createdAt,
																														).getTime() <
																													3600000
																														? countdown(
																																new Date(
																																	receivedGoodbyeLetter.createdAt,
																																),
																																new Date(
																																	receivedGoodbyeLetter.releaseDate,
																																),
																																countdown.MINUTES,
																														  ).toString()
																														: countdown(
																																new Date(
																																	receivedGoodbyeLetter.createdAt,
																																),
																																new Date(
																																	receivedGoodbyeLetter.releaseDate,
																																),
																																countdown.DECADES |
																																	countdown.YEARS |
																																	countdown.MONTHS |
																																	countdown.WEEKS |
																																	countdown.DAYS |
																																	countdown.HOURS,
																														  ).toString(),
																											},
																										)}
																										icon={<BrowseGalleryIcon />}
																										margin='0 0 1rem 0'
																										alertId='smallMuiAlert'
																									/>
																								)}
																								<CustomAlerts
																									info={
																										receivedGoodbyeLetter.prevReleaseDate
																									}
																									message={t(
																										'release_date_inactivity',
																										{
																											date: dayjs(
																												receivedGoodbyeLetter.prevReleaseDate,
																											).format('LLLL'),
																										},
																									)}
																									icon={<TimerIcon />}
																									margin='0 0 1rem 0'
																									severity='warning'
																									alertId='smallMuiAlert'
																								/>
																								<CustomAlerts
																									info={true}
																									message={t('created_on', {
																										date: dayjs(
																											receivedGoodbyeLetter.createdAt,
																										).format('LLLL'),
																									})}
																									icon={<TodayIcon />}
																									margin='0'
																									alertId='smallMuiAlert'
																								/>
																							</div>
																						) : (
																							<Skeleton
																								variant='rectangular'
																								width='80%'
																								height='70vh'
																								animation='wave'
																								sx={{
																									margin: '1rem auto 0 auto',
																								}}
																							/>
																						)}
																					</div>
																				</div>
																			)}
																		</DialogContent>
																	</Dialog>
																)}
															</div>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}
						</Media>
					</div>
				)}
			</div>
		);
	}
}

GoodbyeLetter.propTypes = {
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	clearQueriedUsers: PropTypes.func.isRequired,
	addRecipients: PropTypes.func.isRequired,
	deleteRecipients: PropTypes.func.isRequired,
	uploadGoodbyeLetter: PropTypes.func.isRequired,
	uploadFile: PropTypes.func.isRequired,
	fetchGoodbyeLetter: PropTypes.func.isRequired,
	clearSuccessUpdatingGoodbyeLetter: PropTypes.func.isRequired,
	deleteGoodbyeLetter: PropTypes.func.isRequired,
	setDateTimer: PropTypes.func.isRequired,
	fetchRecipientsData: PropTypes.func.isRequired,
	setAllEmailRecipients: PropTypes.func.isRequired,
	setGoodbyeLetterData: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
	resetAudioFile: PropTypes.func.isRequired,
	clearAudioFile: PropTypes.func.isRequired,
	clearErrorUpdatingLetter: PropTypes.func.isRequired,
	clearAllLetterErrros: PropTypes.func.isRequired,
	launchConfetti: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	clearQueriedUsers,
	addRecipients,
	deleteRecipients,
	uploadGoodbyeLetter,
	uploadFile,
	fetchGoodbyeLetter,
	clearSuccessUpdatingGoodbyeLetter,
	deleteGoodbyeLetter,
	setDateTimer,
	fetchRecipientsData,
	setAllEmailRecipients,
	setGoodbyeLetterData,
	setOpenedDialog,
	resetAudioFile,
	clearAudioFile,
	clearErrorUpdatingLetter,
	clearAllLetterErrros,
	launchConfetti,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(GoodbyeLetter));
