import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function CCPA() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>California Consumer Privacy Act (CCPA) - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0'>
						California Consumer Privacy Act Compliance
					</h1>
					<h1 className='center mgbOnly'>(CCPA)</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<h2>Personal Information Collected:</h2>
					<p>We collect the following categories of personal information:</p>
					<ul className='text'>
						<li>
							<b>Identifiers:</b> Name, email address, IP address.
						</li>
						<li>
							<b>Commercial Information:</b> Records of products or services
							purchased.
						</li>
						<li>
							<b>Internet Activity:</b> Browsing history, search history,
							interaction with our website.
						</li>
						<li>
							<b>Geolocation Data:</b> Physical location.
						</li>
					</ul>
					<h2>Purposes for Collecting Personal Information:</h2>
					<p>We collect personal information for the following purposes:</p>
					<ul className='text'>
						<li>To provide and improve our services.</li>
						<li>To communicate with you.</li>
						<li>To analyze site usage and performance.</li>
						<li>To personalize your experience on Goodbye App.</li>
						<li>To comply with legal obligations.</li>
					</ul>
					<h2>Sources of Personal Information:</h2>
					<p>We collect personal information from:</p>
					<ul className='text'>
						<li>Automated technologies (e.g., cookies, server logs).</li>
						<li>Third-party sources (e.g., social media platforms).</li>
						<li>
							Direct interactions with you (e.g., when you fill out forms on our
							website).
						</li>
					</ul>
					<h2>Sharing Personal Information:</h2>
					<p>
						We share personal information with the following categories of third
						parties:
					</p>
					<ul className='text'>
						<li>
							<b>Analytics Providers:</b> Companies that help us analyze site
							usage. We use Google Analytics from Google, LLC.
						</li>
						<li>
							<b>Service Providers:</b> Companies that provide services on our
							behalf. We use backend cloud computing services from Firebase,
							Inc. Firebase is a platform developed by Google for creating
							mobile and web applications. It provides a variety of tools and
							services to help developers build high-quality apps, including
							databases, analytics, authentication, and hosting.
						</li>
						<li>
							<b>Advertising Partners:</b> Companies that assist with our
							advertising efforts. We use Google Adsense from Google, LLC.
						</li>
					</ul>
					<h2>Consumer Rights under CCPA:</h2>
					<p>California residents have the following rights:</p>
					<ul className='text'>
						<li>
							<b>Right to Know:</b> You have the right to request information
							about our collection and use of your personal information over the
							past 12 months.
						</li>
						<li>
							<b>Right to Delete:</b> You have the right to request the deletion
							of your personal information, subject to certain exceptions.
						</li>
						<li>
							<b>Right to Opt-Out:</b> You have the right to opt-out of the sale
							of your personal information ("Do Not Sell My Personal
							Information").
						</li>
						<li>
							<b>Right to Non-Discrimination:</b> You will not be discriminated
							against for exercising your CCPA rights.
						</li>
					</ul>
					<h2>How to Exercise CCPA Rights:</h2>
					<p>
						To exercise your CCPA rights, please contact us at{' '}
						<Link to='/contact' id='link' className='bold'>
							contact us
						</Link>
						. You may submit a request to know or delete by emailing
						ccpa@goodbyeapp.com. To protect your privacy and security, we will
						verify your identity before processing any request.
					</p>

					<p className='mgtOnly'>
						Under the California Consumer Privacy Act (CCPA), you have certain
						rights regarding your personal information. We collect, use, and
						share personal information as described in this policy. For our
						business customers, we provide a{' '}
						<Link to='/dpa' id='link' className='bold'>
							Data Processing Addendum (DPA)
						</Link>{' '}
						that details our data processing practices in compliance with data
						protection laws, including CCPA and GDPR. To learn more about our
						DPA, please contact us at legal@goodbyeapp.com.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CCPA;
